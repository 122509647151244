<template lang="">
 <div class="container-fluid">
   <div class="row">
    <div class="col-12 ">
      <div class="jumbotron mt-4 text-center" >
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>

        <div class="mt-3">
          Processing your payment ...
          <span class="text-danger">
            Please don't close this page
          </span>
        </div>

      </div>
    </div>
  </div>
 </div>
</template>
<script>

import { db } from "../db";
import {
  ref,
  set,
  query,
  onValue,
  orderByChild,
  equalTo,
  limitToLast,
  endBefore,
  endAt,
  startAfter,
} from "firebase/database";
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      status: '',
    }
  },
  mounted() {
    this.savePaymentToDb()
  },
  methods: {
    savePaymentToDb: function(){

      console.log('tuko hapa');

      const todaysTimeStamp = new Date().getTime();

      const userid = localStorage.getItem("uid");
      const transaction_id = todaysTimeStamp;
      const flw_ref = todaysTimeStamp;
      let time_lapse;

      time_lapse = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);

      const final_lapse = time_lapse.getTime();



      const currentDate = new Date();

      const currentDay = currentDate.getDate();
      const currentMonth = currentDate.getMonth() + 1;  // JavaScript months start from 0
      const currentYear = currentDate.getFullYear();

      let flip = false
      const link_activation = ref(db, "link_activation/weekly_subscription");
      onValue(link_activation, (snapshot) => {
        const data = snapshot.val();
        console.log('here is the data we wabt');
        console.log(data);
        flip = data
        
        console.log(flip);
        if (flip == true) {
         
          let mydb = 'subscriptions/'
          
          set(ref(db, `${mydb}` + `00000-${transaction_id}`), {
            userid: userid,
            transaction_id: transaction_id,
            flw_ref: transaction_id,
            subscription_plan: 'weekly',
            start_date: todaysTimeStamp,
            end_date: final_lapse,
          }).then(() => {
            console.log("iko sawa");
            this.$router
              .replace({
                name: "Home",
              })
              .then(() => {
                const toast = useToast();
                toast.success(
                  "You have successfully subscribed to our VIP Package.",
                  {
                    timeout: 3000,
                  }
                );
              });
          });
        }else{
          this.$router
              .replace({
                name: "Home",
              })
              .then(() => {
                const toast = useToast();
                toast.error(
                  "Link Expired.",
                  {
                    timeout: 3000,
                  }
                );
          });
        }
      });

      // if ((currentDay === 4 && currentMonth === 1 && currentYear === 2024) || (currentDay === 5 && currentMonth === 1 && currentYear === 2024)) {


    }
  },
}
</script>
<style lang="">

</style>
