import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex';
import './assets/theme/js/popper.min.js'
import './assets/theme/js/bootstrap.min.js'
import SmartBanner from  './assets/smart-app-banner.js'
// import VueAnalytics from 'vue-analytics';
import VueGtag from "vue-gtag";
import { VueCookieNext } from 'vue-cookie-next';
import VueScrollTo from 'vue-scrollto'
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import Flutterwave from 'flutterwave-vue3'


import { initializeApp } from 'firebase/app';

import { getAuth, onAuthStateChanged } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyDJRal5jWtmi3QvMX_-bz4sAPwBTWNjn9o",
  authDomain: "premium-betting-predictions.firebaseapp.com",
  databaseURL: "https://premium-betting-predictions-default-rtdb.firebaseio.com",
  projectId: "premium-betting-predictions",
  storageBucket: "premium-betting-predictions.appspot.com",
  messagingSenderId: "408687550823",
  appId: "1:408687550823:web:9c16f095a7e1302f340fc2",
  measurementId: "G-HQCWSYGEFV"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

onAuthStateChanged(auth, (user) => {
  store.dispatch("fetchUser", user);
  store.dispatch("fetchsubscription");
  store.dispatch("fetchdailysubscription");
  store.dispatch("fetchhighrollerslipssubscriptions");
});

const options = {
  position: POSITION.TOP_RIGHT,
  pauseOnFocusLoss: false,
  closeOnClick: true,
  draggable: true,
  hideProgressBar: true,
};





window.$ = window.jQuery = require('jquery')

// Initialize Banner
new SmartBanner({
    daysHidden: 0, // days to hide banner after close button is clicked (defaults to 15)
    daysReminder: 0, // days to hide banner after "VIEW" button is clicked (defaults to 90)
    appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
    title: 'Get The VIP Betting App',
    author: 'For Android',
    button: 'Download',
    store: {
        ios: 'On the App Store',
        android: 'In Google Play',
        windows: 'In Windows store'
    },
    price: {
        ios: 'FREE',
        android: 'FREE',
        windows: 'FREE'
    },
    force:'android',
    // Add an icon (in this example the icon of Our Code Editor)
    icon: "https://www.freepnglogos.com/uploads/play-store-logo-png/play-store-five-educational-android-apps-for-chromebooks-5.png",
    //theme: '', // put platform type ('ios', 'android', etc.) here to force single theme on all device
    //icon: '', // full path to icon image if not using website icon image
    //force: 'windows' // Uncomment for platform emulation
});

createApp(App)
  .use(router)
  .use(store)
  .use(Vuex)
  .use(VueScrollTo)
  .use(VueTelInput)
  .use(VueCookieNext)
  .use(Flutterwave)
  .use(
    VueGtag,
    {
      config: { id: "UA-43252661-2" },
      enabled: true,
    },
    router
  )
  .use(Toast, options)
  .mount("#app");
