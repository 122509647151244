<template>
  <div class="vue-rss-feed">
    <FeedDuplicate :feedUrl="feedUrl" :name="name" :limit="limit"/>
  </div>
</template>

<script>
import FeedDuplicate from "../components/FeedDuplicate.vue";

export default {
  name: "VueRssFeedDuplicate",
  components: {
    FeedDuplicate
  },
  props: {
    feedUrl: String,
    name: String,
    limit: Number
  }
};
</script>

<style scoped>
.vue-rss-feed {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
