<template lang="">
 <div class="container-fluid">
   <div class="row">
    <div class="col-12 ">
      <div class="jumbotron mt-4 text-center" >
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>

        <div class="mt-3">
          Processing your payment ...
          <span class="text-danger">
            Please don't close this page
          </span>
        </div>

      </div>
    </div>
  </div>
 </div>
</template>
<script>

import { db } from "../db";
import {
  ref,
  set,
  query,
  onValue,
  orderByChild,
  equalTo,
  limitToLast,
  endBefore,
  endAt,
  startAfter,
} from "firebase/database";
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      status: '',
      txncd: '',
      msisdn_id: '',
      phone_number: '',
      p1: '',
      p2: '',
      uyt: '',
      agt: '',
      qwh: '',
      ifd: '',
      afd: '',
      poi: '',
      order_id: '',
      ivm: '',
      amount: '',
      channel: '',
    }
  },
  mounted() {
    this.status = this.$route.query.status
    this.txncd = this.$route.query.txncd
    this.msisdn_id = this.$route.query.msisdn_id
    this.phone_number = this.$route.query.msisdn_idnum
    this.p1 = this.$route.query.p1
    this.p2 = this.$route.query.p2
    this.uyt = this.$route.query.uyt
    this.agt = this.$route.query.agt
    this.qwh = this.$route.query.qwh
    this.ifd = this.$route.query.ifd
    this.afd = this.$route.query.afd
    this.poi = this.$route.query.poi
    this.order_id = this.$route.query.id
    this.ivm = this.$route.query.ivm
    this.amount = this.$route.query.mc
    this.channel = this.$route.query.channel

    this.savePaymentToDb()
  },
  methods: {
    savePaymentToDb: function(){

      const userid = localStorage.getItem("uid");
      const transaction_id = this.txncd;
      const flw_ref = this.order_id;
      const todaysTimeStamp = new Date().getTime();
      let time_lapse;

      if (this.p1 == "weekly") {
        time_lapse = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
      } else if (this.p1 == "monthly") {
        time_lapse = new Date(
          Date.now() + 30 * 24 * 60 * 60 * 1000
        );
      } else if (this.p1 == "annually") {
        time_lapse = new Date(
          Date.now() + 90 * 24 * 60 * 60 * 1000
        );
      }else{ if(this.p1 == "daily" || this.p1 == 'highroller')
        time_lapse = new Date(
          Date.now() + 1 * 24 * 60 * 60 * 1000
        );
      }

      const final_lapse = time_lapse.getTime();
      console.log(final_lapse);

      if(this.status == 'aei7p7yrx4ae34' ){
        let mydb = ''
        if(this.p1 == "daily"){
          mydb = 'dailysubscriptions/'
        }
        else if(this.p1 == "highroller"){
          mydb = 'highroller_betting_predictions/'
        }
        else{
          mydb = 'subscriptions/'
        }
        set(ref(db, `${mydb}` + transaction_id), {
          userid: userid,
          transaction_id: transaction_id,
          flw_ref: flw_ref,
          subscription_plan: this.p1,
          start_date: todaysTimeStamp,
          end_date: final_lapse,
        }).then(() => {
          console.log("iko sawa");
          this.$router
            .replace({
              name: "Home",
            })
            .then(() => {
              const toast = useToast();
              toast.success(
                "You have successfully subscribed to our VIP Package.",
                {
                  timeout: 3000,
                }
              );
            });
        });
      }else{
         this.$router
            .replace({
              name: "Home",
            })
            .then(() => {
              const toast = useToast();
              toast.error(
                "There was a problem with the transaction.",
                {
                  timeout: 3000,
                }
              );
            });
      }
    }
  },
}
</script>
<style lang="">

</style>
