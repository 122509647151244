import Vuex from 'vuex';
import userauth from './modules/userAuth'

// createstore


export default new Vuex.Store({
    modules: {
        userauth
    }
});

