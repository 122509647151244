<template>
  <div id="nav">
    <nav
      class="navbar navbar-expand-lg navbar-dark bg-dark white scrolling-navbar"
    >
      <div class="container-fluid">
        <!-- Brand -->
        <router-link to="/" class="navbar-brand waves-effect" href="">
          <img
            src="./assets/images/logologo.png"
            class="mr-4 logologologo"
            alt="Image"
          />
        </router-link>

        <ul
          class="navbar-nav ml-auto d-lg-none mx-auto"
          style="position: relative; right: 15px"
        >
          <li class="nav-item ml-auto" style="padding: 0 !important">
            <!-- <a id="" href="" class="nav-link waves-effect  animated faa-pulse faa-fast" style="border:solid 3px white;border-radius:50%;padding:15px;background:#0679B0;">
            </a> -->
            <a
              href="#"
              data-toggle="modal"
              data-target="#confirmDeleteModal2"
              style=""
            >
              <i
                class="fa fa-gift fa-2x animated faa-tada faa-slow"
                style="color: #fff"
              ></i>
            </a>
            <span
              class="badge badge-success animated faa-tada faa-slow"
              style="
                position: relative;
                border-radius: 50%;
                right: 5px;
                top: 5px;
              "
              >1</span
            >
          </li>
        </ul>

        <!-- Collapse -->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Links -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- Left -->
          <ul class="navbar-nav mr-auto">
            <li class="nav-item dropdown mr-3">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="mynavbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Betting Sites
              </a>
              <div
                class="dropdown-menu bg-dark"
                aria-labelledby="navbarDropdown"
              >
                <a
                  href="https://link.affiliates.cyber.bet/click?b=0&pid=6447&offer_id=53&l=1627312694&sub8=_betting&sub5=403&sub6=1627312694"
                  target="_blank"
                  class="nav-link waves-effect"
                  >CyberBet</a
                >
                <a
                  href="https://www.betway.co.ke/?btag=P90167-PR22293-CM68364-TS1949656"
                  target="_blank"
                  class="nav-link waves-effect"
                  >Betway</a
                >
                <a
                  href="https://ad.22betpartners.com/redirect.aspx?pid=31642&bid=1570"
                  target="_blank"
                  class="nav-link waves-effect"
                  >22Bet</a
                >
              </div>
            </li>

            <li class="nav-item dropdown mr-3">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Predictions
              </a>
              <div
                class="dropdown-menu bg-dark"
                aria-labelledby="navbarDropdown"
              >
                <a
                  href="#"
                  @click="goToFreeSlips"
                  class="nav-link waves-effect"
                  >Free Slips</a>


                <a
                  href="#"
                   @click="goToYesterdayslips"
                  class="nav-link waves-effect"
                  >Result Slips</a
                >

                <router-link
                  v-if="subscription.havesubscription == false"
                  class="nav-link waves-effect"
                  to="/Viptips"
                  >Vip Slips
                </router-link>
                <router-link class="nav-link waves-effect" v-else to="/Viptips"
                  >Vip Slips
                </router-link>

                <router-link
                  v-if="subscription.havesubscription == false"
                  class="nav-link waves-effect"
                  to="/Featured"
                  >Featured Slips
                </router-link>
                <router-link v-else to="/Featured" class="nav-link waves-effect"
                  >Featured Slips
                </router-link>

                <router-link
                  v-if="subscription.havesubscription == false"
                  class="nav-link waves-effect"
                  to="/Highrollers"
                  >High Rollers Slip
                </router-link>
                <router-link v-else to="/Highrollers" class="nav-link waves-effect"
                  >High Rollers Slip
                </router-link>
              </div>
            </li>

            <!-- <li class="nav-item">
            <a class="nav-link waves-effect" href="{% url 'Ecommerce:checkout' %}" target="">CheckOut</a>
          </li> -->
            <li class="nav-item mr-3">
              <!-- <a href="https://play.google.com/store/apps/details?id=chatgpt.betting.tips" target="_blank" class="nav-link waves-effect" >Download apps</a> -->
              <a
                :href="'https://play.google.com/store/apps/details?id=chatgpt.betting.tips'"
                target="_blank"
                class="nav-link waves-effect"
              >
                Download App
              </a>
            </li>

            <li class="nav-item dropdown mr-3">
              <router-link to="/PrivacyPolicy" class="nav-link waves-effect"
                >Privacy Policy
              </router-link>
            </li>
            <li class="nav-item dropdown mr-3">
              <router-link to="/Faq" class="nav-link waves-effect"
                >FAQ
              </router-link>
            </li>
          </ul>

          <!-- Right -->
          <ul class="navbar-nav nav-flex-icons" v-if="displayCrusials == true">
            <li
              v-if="subscription.havesubscription == false"
              class="nav-item my-2"
            >
              <router-link
                to="/premium-content"
                class="nav-link waves-effect"
                style="
                  background: linear-gradient(45deg, gold, #fc742a);
                  border-radius: 10px;
                  color: black;
                "
              >
                <i class="fas fa-crown"></i> Subscribe
              </router-link>
            </li>
            <li
              v-if="subscription.havesubscription == true && user.loggedIn"
              class="nav-item my-2"
            >
              <router-link
                to="/Account"
                class="nav-link waves-effect"
                style="
                  background: linear-gradient(45deg, gold, #fc742a);
                  border-radius: 10px;
                  color: black;
                "
              >
                <i class="fas fa-crown"></i> Account
              </router-link>
            </li>

            <li v-if="user.loggedIn" class="nav-item ml-auto my-2">
              <a @click.prevent="signOutUser" class="nav-link waves-effect">
                Sign Out
              </a>
            </li>
            <li v-else class="nav-item ml-auto my-2">
              <a @click.prevent="signInUser" class="nav-link waves-effect">
                Sign in
              </a>
            </li>
            <li
              class="nav-item ml-auto d-none d-lg-block"
              style="margin-bottom: 0"
            >
              <a
                id="parcel29090"
                href="#"
                data-toggle="modal"
                data-target="#confirmDeleteModal"
                class="nav-link waves-effect"
                style="
                  border: solid 3px white;
                  border-radius: 50%;
                  background: #0679b0;
                "
              >
                <i class="fa fa-gift fa-2x animated faa-bounce"></i>
              </a>

              <span
                class="badge badge-success"
                style="
                  position: relative;
                  bottom: 24px;
                  left: 45px;
                  border-radius: 50%;
                "
                >1</span
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div
      class="modal fade"
      id="confirmDeleteModal"
      tabindex="-1"
      caller-id=""
      role="dialog"
      aria-labelledby="confirmDeleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document" style="padding-top: 70px">
        <div class="modal-content">
          <div
            class="modal-header text-center"
            style="background: #1b9d11; height: 30vh"
          >
            <div class="container text-center">
              <img
                src="./assets/images/popup-bonus-icon-surprise.png"
                alt=""
                style="position: relative; bottom: 65px"
              />

              <h3
                style="
                  color: white;
                  font-weight: 500;
                  position: relative;
                  bottom: 50px;
                "
              >
                Limited Time Offer
              </h3>
            </div>
            <button
              class="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">x</span>
            </button>
          </div>
          <div class="modal-body confirm-delete" style="padding: 0 !important">
            <div class="container text-center">
              <div
                class="card"
                style="position: relative; bottom: 50px; background: #dedfde"
              >
                <div class="card-body">
                  <h1 style="">BEST BETTING SITES FOR KENYA</h1>

                  <img
                    src="./assets/images/betway.png"
                    class="img-responsive"
                    style="height: 100px"
                    alt=""
                  />

                  <h4 style="color: #fe0100; font-weight: 600">
                    Get a 100% bonus up to 15,000 KES!
                  </h4>

                  <h6>
                    <span style="color: #1b9d11; font-weight: 600">
                      1240 players </span
                    >already got this bonus!
                  </h6>

                  <a
                    href="https://www.betway.co.ke/?btag=P90167-PR22293-CM68364-TS1949656"
                    class="btn btn-success btn-block"
                  >
                    <h3>Claim the bonus</h3>
                    <h6>Go to Betway Now!!</h6>
                  </a>
                  <div class="row" style="margin-top: 20px">
                    <div class="col-12">
                      <a
                        href="#"
                        style="margin-top: 30px; color: #7d7d7c"
                        data-dismiss="modal"
                        >No Thanks</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
          <div class="container">
            <button style="float:left;" type="button" class="btn btn-primary btn-sm"
              data-dismiss="modal">Cancel</button>
            <button style="float:right;" type="button" class="btn btn-danger btn-sm" data-dismiss="modal"
              id="confirmDeleteButtonModal">Delete</button>
          </div>
        </div> -->
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="confirmDeleteModal2"
      tabindex="-1"
      caller-id=""
      role="dialog"
      aria-labelledby="confirmDeleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document" style="padding-top: 70px">
        <div class="modal-content" style="width: 90%; left: 20px">
          <div
            class="modal-header text-center"
            style="background: #1b9d11; height: 30vh"
          >
            <div class="container text-center">
              <img
                src="./assets/images/popup-bonus-icon-surprise.png"
                class="animated faa-tada faa-slow"
                alt=""
                style="
                  position: relative;
                  bottom: 65px;
                  height: 100px;
                  left: 20px;
                "
              />

              <h5
                style="
                  color: white;
                  font-weight: 500;
                  position: relative;
                  left: 20px;
                  bottom: 60px;
                "
              >
                Limited Time Offer Mobile
              </h5>
            </div>
            <button
              class="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style="position: relative; bottom: 10px"
                >x</span
              >
            </button>
          </div>
          <div class="modal-body confirm-delete" style="padding: 0 !important">
            <div class="container text-center">
              <div
                class="card"
                style="position: relative; bottom: 45px; background: #dedfde"
              >
                <div class="card-body">
                  <h6 style="font-weight: 600">BEST BETTING SITES FOR KENYA</h6>

                  <img
                    src="./assets/images/betway.png"
                    class="img-responsive"
                    style="height: 50px"
                    alt=""
                  />

                  <h5 style="color: #fe0100; font-weight: 650">
                    Get a 100% bonus up to 15,000 KES!
                  </h5>

                  <h6>
                    <span style="color: #1b9d11; font-weight: 600">
                      1240 players
                    </span>
                    <small> already got this bonus!</small>
                  </h6>

                  <a
                    href="https://www.betway.co.ke/?btag=P90167-PR22293-CM68364-TS1949656"
                    target="_blank"
                    style="padding: 5px !important"
                    class="btn btn-success btn-block"
                  >
                    <h6>Claim the bonus</h6>
                    <h6 style="font-size: 10px; margin-bottom: 0 !important">
                      Go to Betway Now!!
                    </h6>
                  </a>
                  <div class="row" style="margin-top: 20px">
                    <div class="col-12">
                      <a
                        href="#"
                        style="margin-top: 30px; color: #7d7d7c"
                        data-dismiss="modal"
                        >No, Thanks</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
          <div class="container">
            <button style="float:left;" type="button" class="btn btn-primary btn-sm"
              data-dismiss="modal">Cancel</button>
            <button style="float:right;" type="button" class="btn btn-danger btn-sm" data-dismiss="modal"
              id="confirmDeleteButtonModal">Delete</button>
          </div>
        </div> -->
        </div>
      </div>
    </div>
  </div>
  <router-view />

  <div class="footer" style="margin-top: 10px">
    <footer class="myfooter123">
      <div class="card" style="background: #1e2223; color: #fff">
        <div class="card-body">
          <div class="row" style="color: #fff">
            <div class="col-lg-3 col-12">
              <p>
                <img
                  src="./assets/images/logologo.png"
                  class="mr-4"
                  style="height: 60px"
                  alt="Image"
                />
              </p>
              <div class="row">
                <div class="col-12 mb-4">
                  <img
                    src="./assets/images/begambleaware.webp"
                    class="mr-4"
                    style="height: 40px"
                    alt="Image"
                  />
                </div>
                <div class="col-12 mb-4">
                  <img
                    src="./assets/images/gamcare.webp"
                    class="mr-4"
                    style="height: 40px"
                    alt="Image"
                  />
                </div>
                <div class="col-12 mb-4">
                  <img
                    src="./assets/images/gamestop.webp"
                    class="mr-4"
                    style="height: 40px"
                    alt="Image"
                  />
                </div>
                <div class="col-12 mb-4">
                  <img
                    src="./assets/images/_dmca_premi_badge_3.png"
                    class="mr-4"
                    style="height: 40px"
                    alt="Image"
                  />
                </div>
              </div>
              <p style="font-size: 12px; font-weight: 300">
                Our football forecasts are made by professionals, but this does
                not guarantee a profit for you.We ask you to bet responsibly and
                only on what you can afford.Please familiarise yourself with the
                rules for better information. 18+only
              </p>
            </div>
            <div class="col-lg-3 col-6">
              <p style="color: #0679b0; border-bottom: none">Leagues</p>

              <p>Champions League</p>
              <p>Europa League</p>
              <p>Premier League</p>
              <p>FA CUP</p>
              <p>Championship</p>
              <p>La Liga</p>
              <p>Series A</p>
              <p>Ligue 1</p>
              <p>The International (DOTA 2)</p>

              <p>
                <a href="https://play.google.com/store/apps/details?id=chatgpt.betting.tips" target="_blank">
                  <img
                    src="https://www.freepnglogos.com/uploads/play-store-logo-png/play-store-logo-nisi-filters-australia-11.png"
                    style="width: 250px"
                    class="img-fluid"
                    alt=""
                  />
                </a>
              </p>
            </div>
            <div class="col-lg-3 col-6">
              <p style="color: #0679b0; border-bottom: none">Type of Bets</p>

              <p>BTTS</p>
              <p>Correct score</p>
              <p>Double Chance</p>
              <p>Over 3.5</p>
              <p>Over 3.5</p>
              <p>Over 3.5</p>
              <p>Over 3.5</p>
            </div>
            <div class="col-lg-3 col-6">
              <p style="color: #0679b0; border-bottom: none">
                Payments methods
              </p>

              <p>Stripe</p>
              <p>Mpesa</p>
              <p>Crypto</p>
              <!-- methods supported by flutterwave -->
              <p>Visa</p>
              <p>Mastercard</p>
              <p>Bank Transfer</p>
              <!-- no paypal -->
              <p>Mobile Money</p>
              


            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center">
              <h6 class="text-muted">
                Designed By
                <a href="https://drewapps.com" target="_blank">DrewApps</a>
              </h6>
              <h6 class="text-muted">&copy;Copyrights Reserved 2024</h6>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import {
  GoogleAuthProvider,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "./db";
import { mapGetters, mapActions } from "vuex";
import store from "./store";
import {scroller} from 'vue-scrollto/src/scrollTo'

const firstScrollTo = scroller()


export default {
  data() {
    return {
      isActive: false,
      //  user : null,
    };
  },
  created() {
    this.fetchsubscription();
  },

  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      subscription: "subscription",
      displayCrusials: "displayCrusials",
    }),

    isHomeRoute() {
          return this.$route.meta.is_home;
    }
  },



  mounted() {},

  methods: {
    ...mapActions(["fetchsubscription"]),

    togglegiftprop: function () {
      console.log("click");
      this.isActive = !this.isActive;
    },

    signInUser: async function () {
      // authenticate user with google
      const provider = new GoogleAuthProvider();
      try {
        console.log("this");
        store.dispatch("setvisibility", false);
        await signInWithRedirect(auth, provider);
        console.log("login successful");
        this.$router.replace({
          name: "Home",
        });
      } catch (err) {
        console.log(err);
      }
    },

    signOutUser: async function () {
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          console.log("sign out successfull");
          this.$router.replace({
            name: "Home",
          });
        })
        .catch((error) => {
          // An error happened.
        });
    },

    goToFreeSlips: function(){
      if(this.isHomeRoute == 'no'){
        this.$router.push({
          name: "Home",
        }).then(()=>{
          firstScrollTo('#freeslips')
        });

      }else{
        firstScrollTo('#freeslips')
      }
    },
    goToYesterdayslips: function(){
      if(this.isHomeRoute == 'no'){
        this.$router.push({
          name: "Home",
        }).then(()=>{
          firstScrollTo('#yesterslips')
        });

      }else{
        firstScrollTo('#yesterslips')
      }
    }
  },
};
</script>

<style>
@import "assets/theme/css/bootstrap.min.css";
@import "assets/theme/css/mdb.min.css";
@import "assets/theme/css/style.min.css";
@import "assets/smart-app-banner.css";

body {
  background: url("assets/background.png");
}

.myfooter p {
  border-bottom: solid 0.5px rgba(134, 133, 133, 0.808);
}

@media screen and (min-width: 969.98px) {
  .myfooter {
    background: #1e2223;
    height: 95vh;
    padding: 20px;
  }
}
@media screen and (max-width: 969.98px) {
  .myfooter {
    background: #1e2223;
    height: 290vh;
    padding: 20px;
  }
}

@media screen and (min-width: 540px) {
  .logologologo {
    height: 45px;
  }
}
@media screen and (max-width: 560px) {
  .logologologo {
    height: 45px;
  }
}

@keyframes pop {
  0% {
    transform: translate(-50%, -50%) scale(1.25, 0.75);
  }
  50% {
    transform: translate(-50%, -70%) scale(1, 1);
  }
}

#parcel {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  animation: pop 0.4s infinite;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
  color: #fc742a;
}
</style>
