import axios from 'axios';
import { values } from 'lodash';
import { db } from '../../db'
import { ref, set, query, onValue,orderByChild,equalTo ,limitToLast, endBefore, endAt, startAfter } from "firebase/database";


const state = {
  user: {
    loggedIn: false,
    data: null,
  },
  subscription: {
    havesubscription: false,
  },
  dailysubscription: {
    hasdailysubscription: false,
  },
  highrollerslips: {
    hashighrollerslips: false,
  },
  displayCrusialbtns: false,
};

const getters = {
  user(state) {
    return state.user;
  },

  subscription(state) {
    return state.subscription;
  },
  mydailysubscription(state) {
    return state.dailysubscription;
  },
  myhighrollerslips(state) {
    return state.highrollerslips;
  },

  displayCrusials(state) {
    return state.displayCrusialbtns;
  },
};

const actions = {
  fetchUser({ commit }, user) {
    commit("SET_LOGGED_IN", user !== null);
    if (user) {
      commit("SET_USER", {
        displayuid: user.uid,
        email: user.email,
      });
      localStorage.setItem("email", user.email);
    } else {
      commit("SET_USER", null);
    }
  },

  fetchsubscription({ commit }) {
    const userid = localStorage.getItem("uid");
    let todaysTimeStamp = new Date().getTime();
    const funcka = query(
      ref(db, "subscriptions"),
      orderByChild("userid"),
      equalTo(userid)
    );
    onValue(funcka, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        let messages2 = [];
        Object.keys(data).forEach((key) => {
          if (data[key].end_date > todaysTimeStamp) {
            messages2.push({
              id: key,
              userid: data[key].userid,
              startdate: data[key].start_date,
              enddate: data[key].end_date,
            });
          }
        });

        console.log(messages2.length + "is the length");

        if (messages2.length >= 1) {
          const temp_sub = true;
          console.log(temp_sub);
          commit("SET_TODOS", temp_sub);
        } else {
          const temp_sub = false;
          console.log(temp_sub);
          commit("SET_TODOS", temp_sub);
        }
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        const temp_sub = false;
        console.log(temp_sub);
        commit("SET_TODOS", temp_sub);
      }
    });
  },

  fetchdailysubscription({ commit }) {
    const userid = localStorage.getItem("uid");
    if (userid != null) {
      let todaysTimeStamp = new Date().getTime();
      const funcka = query(
        ref(db, "dailysubscriptions"),
        orderByChild("userid"),
        equalTo(userid)
      );
      onValue(funcka, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          let messages5 = [];
          Object.keys(data).forEach((key) => {
            if (data[key].end_date > todaysTimeStamp) {
              messages5.push({
                id: key,
                userid: data[key].userid,
                startdate: data[key].start_date,
                enddate: data[key].end_date,
              });
            }
          });

          console.log(messages5.length + "is the length");

          if (messages5.length >= 1) {
            const temp_sub = true;
            console.log(temp_sub);
            commit("SET_DAILY_SUBSCRIPTON", temp_sub);
          } else {
            const temp_sub = false;
            console.log(temp_sub);
            commit("SET_DAILY_SUBSCRIPTON", temp_sub);
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          const temp_sub = false;
          console.log(temp_sub);
          commit("SET_DAILY_SUBSCRIPTON", temp_sub);
        }
      });
    } else {
      console.log("User is logged out");
      const temp_sub = false;
      console.log(temp_sub);
      commit("SET_DAILY_SUBSCRIPTON", temp_sub);
    }
  },

  fetchhighrollerslipssubscriptions({ commit }) {
    const userid = localStorage.getItem("uid");
    if (userid != null) {
      let todaysTimeStamp = new Date().getTime();
      const funcka = query(
        ref(db, "highroller_subscriptions"),
        orderByChild("userid"),
        equalTo(userid)
      );
      onValue(funcka, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          let messages5 = [];
          Object.keys(data).forEach((key) => {
            if (data[key].end_date > todaysTimeStamp) {
              messages5.push({
                id: key,
                userid: data[key].userid,
                startdate: data[key].start_date,
                enddate: data[key].end_date,
              });
            }
          });

          console.log(messages5.length + "is the length");

          if (messages5.length >= 1) {
            const temp_sub = true;
            console.log(temp_sub);
            commit("SET_HIGHROLLER_SUBSCRIPTIONS", temp_sub);
          } else {
            const temp_sub = false;
            console.log(temp_sub);
            commit("SET_HIGHROLLER_SUBSCRIPTIONS", temp_sub);
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          const temp_sub = false;
          console.log(temp_sub);
          commit("SET_HIGHROLLER_SUBSCRIPTIONS", temp_sub);
        }
      });
    } else {
      console.log("User is logged out");
      const temp_sub = false;
      console.log(temp_sub);
      commit("SET_HIGHROLLER_SUBSCRIPTIONS", temp_sub);
    }
  },
  setvisibility({ commit }, status) {
    commit("SET_DISPLAYCRUCIALS", status);
  },
};

const mutations = {

    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
      localStorage.setItem('loggedIn', value)
    },
    SET_USER(state, data) {
      state.user.data = data;
      if (data) {
        
        localStorage.setItem('uid', data.displayuid)
      } else {
        localStorage.setItem('uid', null)
        state.subscription.havesubscription = false
      }
  },    
    
  SET_TODOS(state, value) {
    state.subscription.havesubscription = value
    },
  SET_DAILY_SUBSCRIPTON(state, value) {
    state.dailysubscription.hasdailysubscription = value
    },
  SET_HIGHROLLER_SUBSCRIPTIONS(state, value) {
    state.highrollerslips.hashighrollerslips = value;
    },
  SET_DISPLAYCRUCIALS(state, value) {
    state.displayCrusialbtns = value
    }

}

export default {
    state,
    getters,
    actions,
    mutations,
}