<template>
    <div class="row my-4">
              <div class="col-12">
                  <h3 style="font-weight:400; border-bottom: solid 3px #0679B0">Download Mobile App</h3>
              </div>
            </div>

            <div class="row my-3">

             
              <div class="col-12">
                 <p>
                    Download this app and you get daily predictions from all around the world leagues ready for you to bet on. Thanks to this application, you will increase the success rate of your predictions.With professional assistance, you can join the winners' club.

                </p>
              </div>
              <div class="col-lg-12 mb-2">
                <img src="../assets/images/reviews.webp" class="img-fluid" alt="">
              </div>
            
              <div class="col-lg-3 col-6 mb-4 text-center">
                <img style="" class="img-fluid" src="https://play-lh.googleusercontent.com/wo4EOzK5-GtuVcoE6PJUrzXRxZWmZsqXqB84xXpb-plJmsdtwb99AnLbW6LoPbDaWw=w1440-h620-rw" alt="">
              </div>
              <div class="col-lg-3 col-6 mb-4 text-center">
                <img style="" class="img-fluid" src="https://play-lh.googleusercontent.com/Lt-bBtmWmWkAEpA5Asc7bO3vFlLFANLTrOcrVR_jzv_Gs_zGvo4yYIBbdm-_8_CN1po=w1440-h620-rw" alt="">
              </div>
              <div class="col-lg-3 col-6 text-center">
                <img style="" class="img-fluid" src="https://play-lh.googleusercontent.com/QgwMiNjHufbbSKl-204QPdEHqWgoTK4ahqeo40KimDSQHTKww7it-hGEYqk0dmdGn_U=w1440-h620-rw" alt="">
              </div>
              <div class="col-lg-3 col-6 text-center">
                <img style="" class="img-fluid" src="https://play-lh.googleusercontent.com/9CCvdw-4amnabaaDPFYrOuWbYjp0OT3IdPNol6yvZxyuFuirK-KmwKbeLBIPHWOfdTo=w1440-h620-rw" alt="">
              </div>

             <div class="col-lg-12 my-2 mb-2 text-center">
               <a href="https://play.google.com/store/apps/details?id=chatgpt.betting.tips" target="_blank">
                    <img src="https://www.freepnglogos.com/uploads/play-store-logo-png/play-store-logo-nisi-filters-australia-11.png" style="width:250px;" class="img-fluid" alt="">
               </a>
              </div>



            </div> 
</template>

<script>
export default {
  name: "DownloadApp",
};
</script>

<style scoped>

</style>


