<template>
    <div>
       <div class="container-fluid">
           <div class="row my-4">
               <div class="col-12">
                   <div class="card">
                       <div class="card-body">
                       <div class="alert alert-warning text-center">
                           <h5> <i class="fas fa-warning"></i> You have to be signed in to access subscription features</h5>
                       </div>

                       <center>
                            <a @click.prevent="signInfrom" class="btn btn-success" style="border-radius:28px;" >
                              <i class="fas fa-user mr-1"></i>  Sign in
                            </a>
                       </center>

                       </div>
                   </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card my-4">
                                <div class="card-body text-center">
                                    <h6>Mean while you may also:</h6>
                                                <DownloadApp />
                                </div>
                                
                            </div>
                        </div>

                        <div class="col-md-6">
                                    <NewsCategories />
                        </div>
                   </div>
               </div>
           </div>
       </div>
    </div>
</template>

<script>
import DownloadApp from '../components/DownloadApp.vue';
import NewsCategories from '../components/NewsCategories.vue';
import Loading from '../components/Loading.vue';
import { GoogleAuthProvider, signInWithRedirect , signOut  ,onAuthStateChanged  } from "firebase/auth";
import { auth } from '../db'

export default {
    

     name: "Signin",
        components: {
            DownloadApp,
            NewsCategories,
            Loading,
        },
    
    methods: {
    signInfrom: async function() {
        this.$root.signInUser();
        console.log('we should reroute');
        this.$router.replace({
            name: "Home"
          });

        // const provider = new GoogleAuthProvider();
        // try {

        //  await signInWithRedirect(auth, provider).then(()=> {
        //     console.log('we are here');
        //      this.$router.push({name:'Home'})    
        //  })
        //  console.log('login successful');
        //  this.$router.replace({
        //     name: "Home"
    //     //   });
    //    } catch(err) {
    //      console.log(err);
    //    }
        


        // if(localStorage.getItem('loggedIn') == 'false'){
        // this.$router.replace({
        //     name: "Home"
        //   });


        // }else if(localStorage.getItem('loggedIn') == 'true')
        // {

        // }

        }

    },
    
}
</script>


<style lang="">
    
</style>