<template>
    <div>
        <div class="" v-if="is_loading_betsites == true">
          <div class="row">
             <div class="col-12 text-center">
              <div class="jumbotron mt-4">
                <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="" v-else>

          <!-- subscription.havesubscription || dailysubscription.hasdailysubscription -->
          <div class="container-fluid my-4" v-if="highrollerslipssubscription.hashighrollerslips == true ||
                      subscription.havesubscription">
              <div class="card mb-2" style="border-radius:10px;background:#082539; color:#fff;">
                <div class="card-body text-center">
                  <h4> <i class="fas fa-gift mr-2"></i> High Rollers Betting Tips</h4>
                  <h6>Slip Odd: {{featuredtips.totalOdd}} </h6> 
                </div>
              </div>

              <div id="horizontal-list" class="d-flex flex-row align-items-center overflow-auto my-4">
                <button class="btn btn-md m-1 text-nowrap" @click="selected_category = ''" :class="selected_category == '' ? 'btn-outline-dark' : 'btn-dark'">
                   All
                </button>
                <button  v-for="category in featuredCategories"  class="btn btn-sm btn-m-1 text-nowrap" :class="selected_category == category ? 'btn-outline-dark' : 'btn-dark'" @click="selected_category = category">
                    <img :src="returnImgFromTag(category)" style="height: 22px; margin-right: 10px;"/>
                    {{ categoryNameFromCode(category) }}
                </button>
                <!-- Add more chips as needed -->
              </div>

      
              <div class="">
              <div class="row my-1">

                <div class="col-md-4 col-12" v-for="tip in returnSelecteCategory" :key="tip.key">
                    <div class="card my-2" style="">
                        <div class="ribbon-wrapper">
                          <div class="ribbon green">High Rollers</div>

                        </div>
                        <div class="card-body">
                        <div class="row">
                          <div class="col-12 text-center" style="font-weight:600;">

                            <span class="" style="font-size:20px;">{{tip.league}}</span>
                          </div>
                          <div class="col-12 text-center my-3" style="font-weight:400;">
                            <span class="" style="font-size:14px;">{{ categoryNameFromCode(tip.tag) }}</span>
                          </div>
                        </div>


                        <div class="row">
                          <div class="col-12 text-center">
                                  <img :src="returnImgFromTag(tip.tag)"  style="position:relative; bottom:30px;" class="bettingicons img-responsive" alt="betway">
                              </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-5 text-center">
                            <!-- <img src="../assets/images/leicester_city_1@2x.webp" style="border-radius:1px; height:40px;" class="mr-2" alt="">   -->
                            <h6 style="margin-bottom:0px; margin-top:3px; font-weight:500;">{{tip.teamOneName}}</h6>
                          </div>

                           <div class="col-2 text-center text-info">
                            Vs
                          </div>
                          <div class="col-5 text-center">
                            <!-- <img src="../assets/images/Manchester_United_1@2x.webp" style="border-radius:1px; height:40px;" alt="" class="mr-2">                         -->
                            <h6 style="margin-bottom:0px; margin-top:3px; font-weight:500;">{{tip.teamTwoName}}</h6>
                          </div>
                          <!-- <div class="col-12 text-center">
                              <span class="mr-2" style="font-weight:500;font-size:11px;">{{tip.team_one_name}}</span> <img src="../assets/images/japan.webp" class="mr-2" alt="">
                              <span class="mr-2" style="font-weight:500;font-size:11px;">-</span>  <img src="../assets/images/australia.webp" alt="" class="mr-2"> <span style="font-weight:500;font-size:11px;">{{tip.team_two_name}}</span>
                          </div> -->
                        </div>


                        <div class="row  my-4">
                          <div class="col-12 text-center">
                          <!-- <span class="badge badge-secondary">{{tip.slip_one_outcome}}</span> <br> -->
                          <span style="font-size:14px;">Prediction:</span>
                          </div>
                          <div class="col-12 text-center">
                            <span style="color:#0679B0;font-weight:500;">{{tip.prediction}}</span>
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-12 text-center">
                          <span style="font-size:11px;"> Best Odds:</span>
                          </div>
                          <div class="col-12 text-center">
                            <span style="color:#fc742a;font-weight:500;">{{tip.odd}}</span>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12 text-center">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <a :href="betsites.link" target="" class="btn btn-primary" style="background:#fff!important; padding:3px; border:solid black 1px; border-top-left-radius:6px;border-bottom-left-radius:6px;">
                                  <div class="row">
                                    <div class="col-12 text-center">
                                        <center><img :src="betsites.icon" class="" style="height:30px; margin-top:5px;" alt="Image"> </center>
                                    </div>
                                  </div>
                                </a>
                                <a :href="betsites.link" target="" class="btn btn-primary" style="background:#fc742a!important;border-top-right-radius:6px;border-bottom-right-radius:6px;">Bet now!</a>
                            </div>
                          </div>

                          <div class="col-12 my-3 text-center">
                            <small style="color:#808080; font-weight:600;">up.to</small> <span style="color:#fc742a; font-weight:600;"> 10000KES Bonus </span>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>

               <div style="width:100%" class="alert alert-info text-center" v-if="returnSelecteCategory.length <= 0">
                        No Betting tips registered under this sports. 
                      </div>
              
              </div>

          </div>

          <div class="container-fluid my-3" v-else>
              <div class="jumbotron text-center">
                  <h1>You need to have an active subscription to access Featured tips</h1>
              </div>
          </div>
        </div>
    </div>
</template>



<script>
import { db } from '../db'
import { ref, set, query, onValue,orderByChild,equalTo ,limitToLast, endBefore, endAt, startAfter } from "firebase/database";
import store from '../store'
import { mapGetters, mapActions } from "vuex";

export default {
    name: "Featured",
    components: {

    },

    data() {
        return {
            featuredtips: [],
            featuredCategories: [],
            betsites: [],
            sportsCategories: [
              { name: 'Football', code: 'fb' },
              { name: 'Basketball', code: 'bb' },
              { name: 'Tennis', code: 'tn' },
              { name: 'Horse Racing', code: 'hr' },
              { name: 'Snooker', code: 'sn' },
              { name: 'Rugby', code: 'ru' },
              { name: 'American Football', code: 'af' },
              { name: 'Cricket', code: 'cr' },
              { name: 'Athletics', code: 'at' },
              { name: 'Boxing', code: 'bx' },
              { name: 'Handball', code: 'hb' },
              { name: 'Ice Hockey', code: 'ih' },
              { name: 'Volleyball', code: 'vb' },
              { name: 'Baseball', code: 'ba' },
              { name: 'Aussie Rules', code: 'ar' },
              { name: 'Cycling', code: 'cy' },
              { name: 'E Sports', code: 'es' },
              { name: 'Table Tennis', code: 'tt' },
              { name: 'Mixed Martial Arts', code: 'mma' },
              { name: 'Darts', code: 'da' },
              { name: 'Water Polo', code: 'wp' }
            ],
            totalodd: 0,
            selected_category: '',
            is_loading_betsites: true,
        }
    },

    created() {



    const starCountRef = ref(db, 'highroller_betting_predictions');
    onValue(starCountRef, (snapshot) => {
       const data = snapshot.val();
      const highrollerSlip = data.highroller_slip;

      let totalOdd = 1;

      let slips = [];
      const slipKeys = ['one', 'two', 'three', 'four', 'five', 'six']

      this.featuredCategories = []

      slipKeys.forEach(key => {
        const slip = {
          league: highrollerSlip[`slip_${key}_league`],
          outcome: highrollerSlip[`slip_${key}_outcome`],
          teamOneName: highrollerSlip[`slip_${key}_team_one_name`],
          teamTwoName: highrollerSlip[`slip_${key}_team_two_name`],
          odd: highrollerSlip[`slip_${key}odd`],
          prediction: highrollerSlip[`slip_${key}prediction`],
          tag: highrollerSlip[`slip_${key}tag`]
        };

        if (!this.featuredCategories.includes(highrollerSlip[`slip_${key}tag`])) {
          this.featuredCategories.push(highrollerSlip[`slip_${key}tag`])
        }

        // Only add slip if it has a valid tag
        if (slip.tag) {
          slips.push(slip);
          totalOdd *= slip.odd; 
        }
      });

      totalOdd = totalOdd.toFixed(2);


      this.featuredtips = { slips: slips, totalOdd: totalOdd };

      store.dispatch("setvisibility", true);
      console.log('we are here bazu')
      console.log(this.featuredtips);
    });


    this.is_loading_betsites = true
    const starCountRef2 = ref(db, 'betting_sites');
    onValue(starCountRef2, (snapshot) => {
      const data = snapshot.val();
      let messages2 = [];
      Object.keys(data).forEach(key => {
          messages2.push({
            id:key,
            betname:data[key].name,
            description: data[key].description,
            link: data[key].url,
            icon: data[key].icon,

          })
      });
      let merand = Math.floor(Math.random() * 2) + 1
      console.log(merand);

      this.betsites = messages2[merand];
      // console.log(this.betsites);
      this.is_loading_betsites = false

    });

    },

    methods: {

      
    categoryNameFromCode(code) {
      try {
        return this.sportsCategories.find(x => x.code == code).name        
      } catch (error) {
        return code
        
      }
    },
        returnImgFromTag(tag) {

      if (tag == 'fb') {
        return require("@/assets/images/footballimage.webp");
      } else if (tag == 'bb') {
        return require("@/assets/images/basketball.webp");
      } else if (tag == 'tn') {
        return require("@/assets/images/tennis.webp");
      } else if (tag == 'hr') {
        return require("@/assets/images/horserace.webp");
      } else if (tag == 'ru') {
        return require("@/assets/images/rugby.webp");
      } else if (tag == 'af') {
        return require("@/assets/images/americanfb.webp");
      } else if (tag == 'at') {
        return require("@/assets/images/athlete.webp");
      }
      else if (tag == 'bx') {
        return require("@/assets/images/boxing.webp");
      }
      else if (tag == 'hb') {
        return require("@/assets/images/handball.webp");
      }
      else if (tag == 'ih') {
        return require("@/assets/images/icehokey.webp");
      }
      else if (tag == 'vb') {
        return require("@/assets/images/voleyball.webp");
      }
      else if (tag == 'sn') {
        return require("@/assets/images/snooker.webp");
      }
      else if (tag == 'cr') {
        return require("@/assets/images/cricket.webp");
      }
      else if (tag == 'ba') {
        return require("@/assets/images/baseball.webp");
      }
      else if (tag == 'ar') {
        return require("@/assets/images/assie-rule.webp");
      }
      else if (tag == 'cy') {
        return require("@/assets/images/cycling.webp");
      }
      else if (tag == 'es') {
        return require("@/assets/images/boxing.webp");
      }
      else if (tag == 'tt') {
        return require("@/assets/images/tt.webp");
      }
      else if (tag == 'mma') {
        return require("@/assets/images/martialarts.webp");
      }
      else if (tag == 'da') {
        return require("@/assets/images/dart.webp");
      }
      else if (tag == 'wp') {
        return require("@/assets/images/waterpollo.webp");
      }
      else {
        return require("@/assets/images/footballimage.webp");
      }

    },
    },

     computed: {
    // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
      user: "user",
      subscription: "subscription",
      dailysubscription: "mydailysubscription",
      highrollerslipssubscription: "myhighrollerslips",
    }),

    returnSelecteCategory() {
      console.log(this.featuredtips);
      if (this.selected_category != '') {
        return this.featuredtips.slips.filter(x => x.tag == this.selected_category)
      } else {
        return this.featuredtips.slips
      }
    },

  },

}
</script>


<style scoped>

  .ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    right: -3px
}
.ribbon {
    font-size: 12px;
    color: #FFF;
    text-transform: uppercase;
    font-family: 'Montserrat Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    letter-spacing: .05em;
    line-height: 15px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .4);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    position: relative;
    padding: 7px 0;
    right: -11px;
    top: 10px;
    width: 100px;
    height: 28px;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    background-color: #dedede;
    background-image: -webkit-linear-gradient(top, #ffffff 45%, #dedede 100%);
    background-image: -o-linear-gradient(top, #ffffff 45%, #dedede 100%);
    background-image: linear-gradient(to bottom, #ffffff 45%, #dedede 100%);
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffdedede', GradientType=0)
}

.ribbon:before,
.ribbon:after {
    content: "";
    border-top: 3px solid #9e9e9e;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    bottom: -3px
}

.ribbon:before {
    left: 0
}
.bettingicons {
  max-height: 50px;
  margin-top: 29px;
  max-width: 50px;
  width: auto;
}

.ribbon:after {
    right: 0
}

.ribbon.green {
    background-color: #2da285;
    background-image: -webkit-linear-gradient(top, #2da285 45%, #227a64 100%);
    background-image: -o-linear-gradient(top, #2da285 45%, #227a64 100%);
    background-image: linear-gradient(to bottom, #2da285 45%, #227a64 100%);
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff2da285', endColorstr='#ff227a64', GradientType=0)
}

.ribbon.green:before,
.ribbon.green:after {
    border-top: 3px solid #113e33
}

.ribbon.red {
    background-color: #bc1a3a;
    background-image: -webkit-linear-gradient(top, #a61733 45%, #bc1a3a 100%);
    background-image: -o-linear-gradient(top, #a61733 45%, #bc1a3a 100%);
    background-image: linear-gradient(to bottom, #a61733 45%, #bc1a3a 100%);
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffa61733', endColorstr='#ffbc1a3a', GradientType=0)
}

.ribbon.red:before,
.ribbon.red:after {
    border-top: 3px solid #8f142c
}


.ribbon.blue {
    background-color: #1a8bbc;
    background-image: -webkit-linear-gradient(top, #177aa6 45%, #1a8bbc 100%);
    background-image: -o-linear-gradient(top, #177aa6 45%, #1a8bbc 100%);
    background-image: linear-gradient(to bottom, #177aa6 45%, #1a8bbc 100%);
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#177aa6', endColorstr='#ff1a8bbc', GradientType=0)
}

.ribbon.blue:before,
.ribbon.blue:after {
    border-top: 3px solid #115979
}

</style>
