import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Subscribe from '../views/Subscribe.vue'
import Signin from '../views/Signin.vue'
import Account from '../views/Account.vue'
import Vip from '../views/Vip.vue'
import Featured from '../views/Featured.vue'
import Highrollers from '../views/Highrollers.vue'
import Loading from '../components/Loading.vue'
import store from '../store'
import { auth , getCurrentUser} from '../db'
import Ipay from '../views/Ipay.vue'
import Callback from '../views/Callback.vue'
import WeeklySub from '../views/WeeklySub.vue'
import MonthlySub from "../views/MonthlySub.vue";
import PremiumContent from '../views/PremiumContent.vue'
import StripeCallBack from '../views/StripeCallBack.vue'
import FlutterwaveCallback from '../views/FlutterwaveCallback.vue'
import Newsletter from '../views/Newsletter.vue'

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      is_home: "yes",
    },
  },
  {
    path: "/ipay/:tarrif",
    name: "ipay",
    component: Ipay,
    meta: {
      is_home: "no",
      requireAuth: true,
    },
  },
  {
    path: "/callback",
    name: "callback",
    component: Callback,
    meta: {
      is_home: "no",
      requireAuth: true,
    },
  },
  {
    path: "/weekelysub",
    name: "weekelysub",
    component: WeeklySub,
    meta: {
      is_home: "no",
      requireAuth: true,
    },
  },
  {
    path: "/monthlySub",
    name: "monthlySub",
    component: MonthlySub,
    meta: {
      is_home: "no",
      requireAuth: true,
    },
  },
  {
    path: "/Loading",
    name: "Loading",
    component: Loading,
    meta: {
      is_home: "no",
    },
  },
  {
    path: "/subscribe",
    name: "Subscribe",
    component: Subscribe,
    meta: {
      requireAuth: false,
      is_home: "no",
    },
  },
  {
    path: "/Viptips",
    name: "Viptips",
    component: Vip,
    meta: {
      requireAuth: true,
      is_home: "no",
    },
  },
  {
    path: "/Featured",
    name: "Featured",
    component: Featured,
    meta: {
      requireAuth: true,
      is_home: "no",
    },
  },
  {
    path: "/Highrollers",
    name: "Highrollers",
    component: Highrollers,
    meta: {
      requireAuth: true,
      is_home: "no",
    },
  },
  {
    path: "/newsletter",
    name: "newsletter",
    component: Newsletter,
    meta: {
      requireAuth: true,
      is_home: "no",
    },
  },
  {
    path: "/Account",
    name: "Account",
    component: Account,
    meta: {
      requireAuth: true,
      is_home: "no",
    },
  },

  {
    path: "/stripe-1",
    name: "stripe-1",
    component: StripeCallBack,
    meta: {
      requireAuth: true,
      is_home: "no",
    },
  },
  {
    path: "/flutterwave",
    name: "flutterwave",
    component: FlutterwaveCallback,
    meta: {
      requireAuth: true,
      is_home: "no",
    },
  },
  {
    path: "/Signin",
    name: "Signin",
    component: Signin,

    meta: {
      Guestonly: true,
      is_home: "no",
    },
  },
  {
    path: "/news",
    name: "News",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/News.vue"),
  },
  {
    path: "/nba",
    name: "Nba",
    component: () => import(/* webpackChunkName: "about" */ "../views/Nba.vue"),
  },
  {
    path: "/Soccer",
    name: "Soccer",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Soccer.vue"),
  },
  {
    path: "/MLB",
    name: "MLB",
    component: () => import(/* webpackChunkName: "about" */ "../views/MLB.vue"),
  },
  {
    path: "/NFL",
    name: "NFL",
    component: () => import(/* webpackChunkName: "about" */ "../views/NFL.vue"),
  },
  {
    path: "/NCAAFB",
    name: "NCAAFB",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NCAAFB.vue"),
  },
  {
    path: "/Golf",
    name: "Golf",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Golf.vue"),
  },
  {
    path: "/Olympics",
    name: "Olympics",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Olympics.vue"),
  },
  {
    path: "/Tennis",
    name: "Tennis",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Tennis.vue"),
  },
  {
    path: "/HorseRacing",
    name: "HorseRacing",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HorseRacing.vue"),
  },
  {
    path: "/Motor",
    name: "Motor",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Motor.vue"),
  },
  {
    path: "/PrivacyPolicy",
    name: "PrivacyPolicy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PrivacyPolicy.vue"),
  },
  {
    path: "/Faq",
    name: "Faq",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Faq.vue"),
  },
  {
    path: "/premium-content",
    name: "PremiumContent",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PremiumContent.vue"),
  },

  {
    path: "/:pathMatch(.*)*",
    name: "Home",
    component: Home,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,

  scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
})

router.beforeEach((to, from, next) => {
  $('.navbar-collapse').collapse('hide'); //Be sure to import jquery
  const requireAuth = to.matched.some(record => record.meta.requireAuth)
  const Guestonly = to.matched.some(record => record.meta.Guestonly)

  const  user = localStorage.getItem('loggedIn')
  console.log(user + 'is the mf');
  console.log(requireAuth + 'is requre auth');

  if (requireAuth === true && localStorage.getItem('loggedIn') == 'false' ) {
    console.log('we are here');
    next('/Signin')

  }
  else if (Guestonly && localStorage.getItem('loggedIn') == 'true') {
    next('subscribe/')
  }

  else {
    console.log('mf bock');
    next()
  }

});



export default router
