import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { reject } from 'lodash';

const firebaseConfig = {
  apiKey: "AIzaSyDJRal5jWtmi3QvMX_-bz4sAPwBTWNjn9o",
  authDomain: "premium-betting-predictions.firebaseapp.com",
  databaseURL: "https://premium-betting-predictions-default-rtdb.firebaseio.com",
  projectId: "premium-betting-predictions",
  storageBucket: "premium-betting-predictions.appspot.com",
  messagingSenderId: "408687550823",
  appId: "1:408687550823:web:9c16f095a7e1302f340fc2",
  measurementId: "G-HQCWSYGEFV"
};

const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

const user = auth.currentUser;
const getCurrentUser = function () {
  
  new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          console.log(uid);
          // ...
        } else {
          console.log('user is signed out');
          // User is signed out
          // ...
      }
    });
    

  })


  
}






const db = getDatabase(app);


export { db , user, auth, getCurrentUser };
