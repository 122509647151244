<template lang="">
 <div class="container-fluid">
   <div class="row mt-4" >
     <div class="col-md-4 mb-4 offset-md-4">
          <div class="card-mine text-center" :class="card_color">
            <div class="title" v-if="this.subscription_type == 'weekly'">
              <i class="fas fa-7"></i>
              <h2>Weekly</h2>
            </div>
            <div class="title" v-else-if="this.subscription_type == 'monthly'">
              <i class="fas fa-3" aria-hidden="true"></i>
              <i class="fas fa-0" aria-hidden="true"></i>
              <h2>Monthly</h2>
            </div>
            <div class="title" v-else-if="this.subscription_type == 'annually'">
             <i class="fas fa-3" aria-hidden="true"></i>
              <h2>Months</h2>
            </div>
            <div class="title" v-else-if="this.subscription_type == 'daily' || this.subscription_type == 'highroller'">
             <i class="fas fa-1" aria-hidden="true"></i>
              <h2>Day</h2>
            </div>
            <div class="price">
              <h4><i class="icon-arrow-down15 icon-4x"></i></h4>
            </div>
            <div class="option">
              <ul>
                <li v-if="this.subscription_type == 'weekly'">
                  By Subscribing you get access to premium predictions for one
                  week
                </li>
                <li v-else-if="this.subscription_type == 'monthly'">
                  By Subscribing you get access to premium predictions for one
                  Month
                </li>
                <li v-else-if="this.subscription_type == 'annually'">
                  By Subscribing you get access to premium predictions for Three Months
                </li>
                <li v-else-if="this.subscription_type == 'daily' || this.subscription_type == 'highroller'">
                  By Subscribing you get access to Featured Tips for one Day
                </li>
                <li>
                  <h2
                    style="
                      font-weight: 700;3000
                      color: white;
                      font-size: 40px !important;
                    "
                  >
                   <span v-if="this.subscription_type == 'weekly'"> $10</span>
                   <span v-else-if="this.subscription_type == 'monthly'"> $35</span>
                   <span v-else-if="this.subscription_type == 'annually'"> $60</span>
                   <span v-else-if="this.subscription_type == 'daily' || this.subscription_type == 'highroller'"> $1</span>
                  </h2>
                </li>
              </ul>
            </div>

            <form ref="form" method="post" action="https://payments.ipayafrica.com/v3/ke">
              <input name="live" value="1" type="hidden" >
              <input name="oid" :value="unique_ref" type="hidden">
              <input name="inv" :value="unique_ref" type="hidden">
              <input name="mpesa" value="1" type="hidden">
              <input name="equity" value="1" type="hidden">
              <input name="bonga" value="1" type="hidden">
              <input name="unionpay" value="1" type="hidden">
              <input name="creditcard" value="1" type="hidden">
              <input name="mvisa" value="1" type="hidden">
              <input name="pesalink" value="1" type="hidden">
              <input name="airtel" value="1" type="hidden">
              <input name="vooma" value="1" type="hidden">
              <input name="ttl" :value="amount" type="hidden">
              <input name="tel"  v-model="phone_number"  required placeholder="Enter Mpesa Number" class="form-control" type="text">
              <input name="eml" :value="email" type="hidden">
              <input name="vid" value="drewapps" type="hidden">
              <input name="curr" value="KES" type="hidden">
              <input name="p1" :value="subscription_type" type="hidden">
              <input name="p2" value="" type="hidden">
              <input name="p3" value="" type="hidden">
              <input name="p4" value="" type="hidden">
              <input name="cbk" value="https://thevipbettingtips.com/#/callback" type="hidden">
              <input name="lbk" value="https://thevipbettingtips.com/#/Subscribe" type="hidden">
              <input name="cst" value="1" type="hidden">
              <input name="crl" value="2" type="hidden">
              <input name="hsh" :value="hash_value" type="hidden">
              <a href="#" id="continue_btn" class="projects scroll-to-top rounded" style="border-radius:50px; z-index:10000;margin-top: 30px" v-on:click.prevent="submit"><i class="fas fa-check text-success mr-1"></i> Confirm  <span id="btn_spinner" class="spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
              </a>
              <div id="message"  class="text-white mt-4 d-none" style="border: solid 1px #fff;">
                <p class="mt-2">We strongly recommend <b>MPESA EXPRESS</b> for faster checkouts & payment processing. <br> <span class="text-warning">Please wait as you get redirected to the checkout page</span></p>
              </div>
              <div class="text-white mt-3">
                <span class="line-hr">
                  or
                </span>
              </div>

               <router-link
                to="/Subscribe"
                class="projects scroll-to-top rounded"
                >Change Package</router-link
              >
            </form>
          </div>
        </div>
    <!-- <div class="col-md-6 offset-md-3">
      <div class="jumbotron mt-4 text-center">
        <div class="alert alert-info">
          <p>
            Subscription: <b> {{subscription_type}}</b>
          </p>
          <p>
            Amount: <b> KSH {{amount}}.00</b>
          </p>
        </div>
        <p class="text-muted">Please Enter your phone number to proceed to payment:</p>

      </div>
    </div> -->
  </div>
 </div>
</template>
<script>
import axiosInst from "../services/api";
import { useToast } from "vue-toastification";

export default {
   data() {
      return {
          store: 'yeap',
          unique_ref: '',
          hash_value: '',
          amount: '',
          phone_number: '',
          email: 'ptachege16@gmail.com',
          subscription_type: '',
          is_fetching: false,
          card_color: 'card_red',
      }
  },
  methods : {
    submit : async function(){
      if(this.phone_number == '' || this.phone_number == null){
        const toast = useToast();
        toast.error(
          "You must enter a phone number.",
          {
            timeout: 3000,
          });

        return false
      }else if(this.phone_number.toString().length < 10 || this.phone_number.toString().length > 12){
        const toast = useToast();
        toast.error(
          "You must enter a valid phone number.",
          {
            timeout: 3000,
          });

        return false
      }else if(isNaN(this.phone_number)){
        const toast = useToast();
        toast.error(
          "Number can not contain text or foreign characters.",
          {
            timeout: 3000,
          });

        return false
      }

      $("#continue_btn").addClass("disabled");
      $("#btn_spinner").addClass("spinner-border ");
      $("#message").removeClass("d-none");

      const form = this.$refs.form
      const formData = {
        live: "1",
        oid: this.unique_ref,
        inv: this.unique_ref,
        amount: this.amount,
        tel: this.phone_number, //customer mobile number
        eml: this.email,
        vid: "drewapps", //replace with own vendor id (all lowercase)
        curr: "KES",
        p1: this.subscription_type,
        p2: "",
        p3: "",
        p4: "",
        cbk: "https://thevipbettingtips.com/#/callback", //callback url
        cst: 1,
        crl: 2,
      }

      console.log('posted to backend');

      await axiosInst
        .post(`/payment/get_hash`, formData)
        .then((response) => {
          let { data } = response;
          this.hash_value = data.hash
          console.log(this.hash_value);
        })
        .catch((error) => {
          console.log(error);
        });
        form.submit()

    }
  },
  created() {
    let date = new Date();
    this.unique_ref = date.getTime().toString();
    this.subscription_type = this.$route.params.tarrif
    this.email = localStorage.getItem('email')

    if (this.subscription_type == "weekly") {
      this.amount = 1200
      this.card_color = 'card_red'
    } else if (this.subscription_type == "monthly") {
      this.amount = 4025
      this.card_color = 'card_violet'

    } else if (this.subscription_type == "annually") {
      this.amount = 6900
      this.card_color = 'card_three'
    }else if(this.subscription_type == "daily" || this.subscription_type == 'highroller'){
      this.amount = 200
      this.card_color = 'card_red'
    }
  },
  
}
</script>
<style scoped src="@/assets/style.css">
.line-hr {
    position: relative;
    font-size: 30px;
    z-index: 1;
    overflow: hidden;
    text-align: center;
}
.line-hr:before, .line-hr:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: '\a0';
    background-color: red;
}
.line-hr:before {
    margin-left: -50%;
    text-align: right;
}
</style>
