<template>
  <div class="col-lg-3 d-none d-lg-block">
    <div class="row">
      <div class="col-12">
        <div class="card mb-3" style="background: #1e2223; border-radius: 10px">
          <div class="card-body">
            <div class="row">
              <div class="col-8">
                <h6 style="color:#fff;x">New customer offers</h6>
              </div>
              <div class="col-4 text-right">
                <img
                  src="../assets/images/ke.svg"
                  class="img-responsive img-fluid flagkenya"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3" v-for="message in documents" :key="message.key">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <center>
                  <div class="">
                    <center>
                      <img
                        
                        :src="message.icon"
                        class="bettingicons img-responsive"
                        alt="betway"
                      />
                    
                    </center>
                  </div>
                </center>
                <h3 class="my-4" style="color: green; font-weight: 700">
                  {{ message.description }}
                </h3>
                <a
                  :href="message.link"
                  target="_blank"
                  class="btn btn-success btn-sm btn-block"
                  style="font-size: 16px; font-weight: 500"
                  >GET Bonus</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row my-3">
            <div class="col-12">
                <div class="card" style="border:solid 2px #B514B5 ">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 text-center">
                        <center>
                           <div class="roundround">
                              <center>
                                <img src="../assets/images/melbet.webp" class="bettingicons img-responsive" alt="betway">
                              </center>
                           </div> 
                        </center>
                        <h3 class="my-4" style="color:green;font-weight:700;">
                         200% bonus up to 26,280 KES
                        </h3>
                        <a href="" class="btn btn-success btn-sm btn-block" style="font-size:16px; font-weight:500;">GET Bonus</a>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-12">
                <div class="card" style="border:solid 2px #019898">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 text-center">
                        <center>
                           <div class="roundround">
                              <center>
                                <img src="../assets/images/22bet.webp" class="bettingicons img-responsive" alt="betway">
                              </center>
                           </div> 
                        </center>
                        <h3 class="my-4" style="color:green;font-weight:700;">
                        15,000 KES Bonus
                        </h3>
                        <a href="" class="btn btn-success btn-sm btn-block" style="font-size:16px; font-weight:500;">GET Bonus</a>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div> -->
    <!-- <div class="row my-3">
            <div class="col-12">
                <div class="card" style="border:solid 2px #259425;">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 text-center">
                        <center>
                           <div class="roundround">
                              <center>
                                <img src="../assets/images/cyber-bet.svg" class="bettingicons img-responsive" alt="betway">
                              </center>
                           </div> 
                        </center>
                        <h3 class="my-4" style="color:green;font-weight:700;">
                        15,000 KES Bonus
                        </h3>
                        <a href="" class="btn btn-success btn-sm btn-block" style="font-size:16px; font-weight:500;">GET Bonus</a>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-12">
                <div class="card" style="border:solid 2px #259425;">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 text-center">
                        <center>
                           <div class="roundround">
                              <center>
                                <img src="../assets/images/cyber-bet.svg" class="bettingicons img-responsive" alt="betway">
                              </center>
                           </div> 
                        </center>
                        <h3 class="my-4" style="color:green;font-weight:700;">
                        DEPOSIT 100 KES GET EXTRA 100 KES!
                        </h3>
                        <a href="" class="btn btn-success btn-sm btn-block" style="font-size:16px; font-weight:500;">GET Bonus</a>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div> -->
  </div>
</template>

<script>
import { db } from "../db";
import { ref, onValue } from "firebase/database";

export default {
  name: "Offers",

  data() {
    return {
      documents: [],
    };
  },

  created() {
    const starCountRef = ref(db, "betting_sites");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      let messages = [];
      Object.keys(data).forEach((key) => {
        messages.push({
          id: key,
          betname: data[key].name,
          description: data[key].description,
          link: data[key].url,
          icon: data[key].icon,
        });
      });

      this.documents = messages;
      this.loading = false;
      console.log(this.documents);
    });
  },
};
</script>

<style scoped>
@media screen and (min-width: 600px) {
  .mine {
    margin-top: 60px;
  }

  .icon-bubbles {
    font-size: 40px;
  }
}

section {
  display: grid;
  grid-template-columns: var(--page-margin) [center-start] 1fr [center-end] var(
      --page-margin
    );
}

section > * {
  grid-column: center;
}

.cards-wrapper {
  grid-column: center-start / -1;
  overflow: auto;
  display: flex;
  padding-bottom: 1.9em;
}

/* .cards-wrapper::-webkit-scrollbar {
       width: 100px;
    } */

.cards-wrapper .card {
  /* background-color: #ced3d3; */
  color: #333;
  height: 350px;
  min-width: 250px;
  margin-right: 5px;
  margin-left: 3px;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
}

.cards-wrapper .card p {
  background-color: rgba(124, 68, 4, 0.932);
  margin-top: 115px;
  font-size: 14px;
  color: white;
  width: 60px;
  height: 20px;
  text-align: center;
  border-radius: 10px;
}

.cards-wrapper .card p:last-child {
  margin-right: 0;
}

.cards-wrapper .card p::after {
  content: "";
  display: block;
  min-width: var(--page-margin);
}

.item {
  position: relative;
}

.notify-badge {
  position: absolute;
  right: 35%;
  top: 10%;
  background: green;
  height: 25px;
  width: 20px;
  border-radius: 50%;
  border: solid 3px white;
  color: white;
  padding: 5px 10px;
}

.remove-default-btn:focus {
  outline: none;
}

/* the non scroll section styles here */

.fontweightpara {
  font-weight: 400;
}

.flagkenya {
  height: 30px;
  width: 30px;
}

.jumbotronMain {
  border-left: solid 2px teal;
  border-right: solid 2px teal;
}

#topbookmarkerbold {
  font-weight: 500;
}

@media screen and (max-width: 1199.98px) {
  .ballhand {
    position: relative;
    left: -30px;
  }
}

@media screen and (max-width: 991.98px) {
  .ballhand {
    position: relative;
    left: 100px;
  }
}

@media screen and (max-width: 769px) {
  .ballhand {
    position: relative;
    left: 70px;
  }
}

@media screen and (max-width: 500px) {
  .ballhand {
    position: relative;
    left: -10px;
  }
}

/* betting icons */

.bettingicons {
  max-height: 50px;
  margin-top: 10px;
  max-width: 50px;
  width: auto;
}

.bettingicons2 {
  max-height: 50px;
  margin-top: 20px;
  max-width: 50px;
  width: auto;
}
.bettingicons3 {
  max-height: 30px;
  margin-top: 10px;
  max-width: 30px;
  width: auto;
}

.roundround {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background-color: black;
}
.roundround2 {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  background-color: black;
}
.roundround3 {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: black;
}

.pointer {
  cursor: pointer;
}
.clickable-row:hover {
  background: #fffbfb62;
}
p {
  font-weight: 400;
}

.skeleton {
  animation: skeleton-loading 0.5s linear infinite alternate;
}

.skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 10px;
}
.skeleton-btn {
  width: 100px;
  height: 2rem;
  margin-bottom: 0.25rem;
  border-radius: 10px;
}

.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
</style>


