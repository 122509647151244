<template>
  <div class="row mb-4">
    <div class="col-12">
      <div class="card">
      <div class="card-body" style="border-left: solid 3px teal;border-right: solid 3px teal;">
        <div class="row">
          <div class="col-md-6 text-center">
             <img v-bind:src="`${article.enclosure.url}`" style="border-radius:10px;" class="img-fluid img-thumbnail" alt="No image">
          </div>
          <div class="col-md-6 ">
              <h3  v-html="article.title" class="my-2" style="font-weight:750;"></h3>
              <p class="content my-2" v-html="article.content"></p>
              <br>

              <p style="float:right; font-weight:600;">
                 <!-- <span class="hostname">{{this.getHostname()}}</span> -->
                <span v-if="article.isoDate" class="middot">&bull;</span>
                <!-- <span class="datetime">{{this.getDateTime()}}</span> ago -->
              </p>
          </div>
        </div>
      
      </div>
      </div>
  </div>
  </div>
</template>

<script>
const parseDate = tdate => {
  const systemDate = new Date(Date.parse(tdate));
  const userDate = new Date();

  const diff = Math.floor((userDate - systemDate) / 1000);
  if (diff < 59) {
    return diff + "s";
  }

  if (diff <= 3540) {
    return Math.round(diff / 60) + "m";
  }

  if (diff <= 86400) {
    return Math.round(diff / 3600) + "h";
  }

  if (diff < 604800) {
    return Math.round(diff / 86400) + "d";
  }

  return systemDate.toString().substring(4, 10);
};

export default {
  name: "Article",
  props: ["article"],
  methods: {
    getHostname() {
      try {
        const urlObj = new URL(this.article.link);
        return urlObj.hostname.replace("www.", "").replace("ww2.", "");
      } catch (e) {
        console.error(e.toString());
      }
    },
    getDateTime() {
      if (this.article.isoDate) {
        return parseDate(this.article.isoDate);
      }
    }
  }
};
</script>

<style scoped>
h3 {
  margin: 0;
  padding: 0;
  font-size: 17px;
}
.body .content {
  margin-top: 5px;
}
.footer {
  font-size: 13px;
  color: #777;
  display: flex;
  align-items: flex-end;
}
.middot {
  font-size: 12px;
  margin: 0 8px;
}
a {
  color: #42b983;
}
</style>
