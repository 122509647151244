<template>
  <div class="card my-3">
              <div class="card-header" style="background:#082539;border-bottom:solid 4px #E52017;">    
                <div class="row">
                  <div class="col-12 text-center" style="color:#fff;">
                    <h4>News Categories</h4>                    
                  </div>
                </div>            

              </div>
              <div class="card-body">
                <div class="row mb-2">
                 <div class="col-12">
                    <div class="card" style="color:#333;font-weight:700;border-radius:10px;">
                      <div class="card-body" style="border-radius:10px">
                        <div class="row">
                          <div class="col-2">
                            <img src="../assets/images/basketball.webp" alt="" style="height:50px;">
                          </div>
                          <div class="col-8 text-center" style="margin-top:6px">
                            <router-link to="/Nba"  class="nav-link waves-effect"> NBA News</router-link> 
                          </div>
                          <div class="col-1" style="margin-top:6px"><i class="fa-solid fa-chevron-right"></i></div>
                        </div>                        
                      </div>
                    </div>
                 </div>
                </div>

                <div class="row mb-2">
                 <div class="col-12">
                    <div class="card" style="color:#333;font-weight:700;border-radius:10px;">
                      <div class="card-body" style="border-radius:10px">
                        <div class="row">
                          <div class="col-2">
                            <img src="../assets/images/football.webp" alt="" style="height:50px;">
                          </div>
                          <div class="col-8 text-center" style="margin-top:6px">
                            <router-link to="/Soccer"  class="nav-link waves-effect"> Football News</router-link> 
                          </div>
                          <div class="col-1" style="margin-top:6px"><i class="fa-solid fa-chevron-right"></i></div>
                        </div>                        
                      </div>
                    </div>
                 </div>
                </div>
                <div class="row mb-2">
                 <div class="col-12">
                    <div class="card" style="color:#333;font-weight:700;border-radius:10px;">
                      <div class="card-body" style="border-radius:10px">
                        <div class="row">
                          <div class="col-2">
                            <img src="../assets/images/basketball.webp" alt="" style="height:50px;">
                          </div>
                          <div class="col-8 text-center" style="margin-top:6px">
                            <router-link to="/MLB"  class="nav-link waves-effect"> MLB News</router-link> 
                          </div>
                          <div class="col-1" style="margin-top:6px"><i class="fa-solid fa-chevron-right"></i></div>
                        </div>                        
                      </div>
                    </div>
                 </div>
                </div>

                <div class="row mb-2">
                 <div class="col-12">
                    <div class="card" style="color:#333;font-weight:700;border-radius:10px;">
                      <div class="card-body" style="border-radius:10px">
                        <div class="row">
                          <div class="col-2">
                            <img src="../assets/images/NFL.webp" alt="" style="height:50px;">
                          </div>
                          <div class="col-8 text-center" style="margin-top:6px">
                            <router-link to="/NCAAFB"  class="nav-link waves-effect"> NFL News</router-link> 
                          </div>
                          <div class="col-1" style="margin-top:6px"><i class="fa-solid fa-chevron-right"></i></div>
                        </div>                        
                      </div>
                    </div>
                 </div>
                </div>
                <!-- <div class="row mb-2">
                 <div class="col-12">
                    <div class="card" style="color:#333;font-weight:700;border-radius:10px;">
                      <div class="card-body" style="border-radius:10px">
                        <div class="row">
                          <div class="col-2">
                            <img src="../assets/images/golf.webp" alt="" style="height:50px;">
                          </div>
                          <div class="col-8 text-center" style="margin-top:6px">
                            <router-link to="/Golf"  class="nav-link waves-effect"> Golf News</router-link> 
                          </div>
                          <div class="col-1" style="margin-top:6px"><i class="fa-solid fa-chevron-right"></i></div>
                        </div>                        
                      </div>
                    </div>
                 </div>
                </div> -->


                <div class="row mb-2">
                 <div class="col-12">
                    <div class="card" style="color:#333;font-weight:700;border-radius:10px;">
                      <div class="card-body" style="border-radius:10px">
                        <div class="row">
                          <div class="col-2">
                            <img src="../assets/images/olympics.webp" alt="" style="height:50px;">
                          </div>
                          <div class="col-8 text-center" style="margin-top:6px">
                            <router-link to="/Olympics"  class="nav-link waves-effect"> Olympics News</router-link> 
                          </div>
                          <div class="col-1" style="margin-top:6px"><i class="fa-solid fa-chevron-right"></i></div>
                        </div>                        
                      </div>
                    </div>
                 </div>
                </div>


                <div class="row mb-2">
                 <div class="col-12">
                    <div class="card" style="color:#333;font-weight:700;border-radius:10px;">
                      <div class="card-body" style="border-radius:10px">
                        <div class="row">
                          <div class="col-2">
                            <img src="../assets/images/tenis.webp" alt="" style="height:50px;">
                          </div>
                          <div class="col-8 text-center" style="margin-top:6px">
                            <router-link to="/Tennis"  class="nav-link waves-effect"> Tennis News</router-link> 
                          </div>
                          <div class="col-1" style="margin-top:6px"><i class="fa-solid fa-chevron-right"></i></div>
                        </div>                        
                      </div>
                    </div>
                 </div>
                </div>

                <div class="row mb-2">
                 <div class="col-12">
                    <div class="card" style="color:#333;font-weight:700;border-radius:10px;">
                      <div class="card-body" style="border-radius:10px">
                        <div class="row">
                          <div class="col-2">
                            <img src="../assets/images/horseracing.webp" alt="" style="height:50px; position:relative;right:20px;">
                          </div>
                          <div class="col-8 text-center" style="margin-top:6px">
                            <router-link to="/HorseRacing"  class="nav-link waves-effect"> Horse Racing News</router-link> 
                          </div>
                          <div class="col-1" style="margin-top:6px"><i class="fa-solid fa-chevron-right"></i></div>
                        </div>                        
                      </div>
                    </div>
                 </div>
                </div>

                <div class="row mb-2">
                 <div class="col-12">
                    <div class="card" style="color:#333;font-weight:700;border-radius:10px;">
                      <div class="card-body" style="border-radius:10px">
                        <div class="row">
                          <div class="col-2">
                            <img src="../assets/images/motor.webp" alt="" style="height:50px;">
                          </div>
                          <div class="col-8 text-center" style="margin-top:6px">
                            <router-link to="/Motor"  class="nav-link waves-effect"> Motor News</router-link> 
                          </div>
                          <div class="col-1" style="margin-top:6px"><i class="fa-solid fa-chevron-right"></i></div>
                        </div>                        
                      </div>
                    </div>
                 </div>
                </div>


            </div>
          </div>
</template>

<script>
export default {
  name: "NewsCategories",
};
</script>

<style scoped>

</style>


