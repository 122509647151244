<template lang="">
 <div class="container-fluid">
   <div class="row">
    <div class="col-12 ">
      <div class="jumbotron mt-4 text-center" >
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>

        <div class="mt-3">
          Processing your payment ...
          <span class="text-danger">
            Please don't close this page
          </span>
        </div>

      </div>
    </div>
  </div>
 </div>
</template>
<script>

import { db } from "../db";
import {
    ref,
    set,
    query,
    onValue,
    orderByChild,
    equalTo,
    limitToLast,
    endBefore,
    endAt,
    startAfter,
} from "firebase/database";
import { useToast } from "vue-toastification";

export default {
    data() {
        return {
            tarrif: '',           
        }
    },
    mounted() {
        // get params from url

        this.$router
            .replace({
                name: "Home",
            })
            .then(() => {
                const toast = useToast();
                toast.success(
                    "You have successfully subscribed to our VIP Package.",
                    {
                        timeout: 3000,
                    }
                );
                localStorage.removeItem("saved_tarrif");
            });

        return;
        let tarrif = localStorage.getItem("saved_tarrif")

        if(typeof tarrif == 'undefined' || tarrif == null){
            this.$router
                .replace({
                    name: "Home",
                })
                .then(() => {
                    const toast = useToast();
                    toast.error(
                        "Transaction Failed. Please Try Again. No Tarrif Defined",
                        {
                            timeout: 3000,
                        }
                    );
                    localStorage.removeItem("saved_tarrif");

                });

        }else{
            this.tarrif = tarrif;

            if (this.$route.query.status == "failed"){
                this.$router
                    .replace({
                        name: "Home",
                    })
                    .then(() => {
                        const toast = useToast();
                        toast.error(
                            "Transaction Failed. Please Try Again.",
                            {
                                timeout: 3000,
                            }
                        );
                        localStorage.removeItem("saved_tarrif");

                    });
            }else{
                this.savePaymentToDb()
            }
        }

    },
    methods: {
        savePaymentToDb: function () {

            const userid = localStorage.getItem("uid");

            let date = new Date();
            let x = date.getTime().toString();
            x = Number(x);

            console.log(x);
            

            const transaction_id = 'flutterwave' + x;
            const flw_ref = x;

            const todaysTimeStamp = new Date().getTime();
            let time_lapse;

            if (this.tarrif == "weekly") {
                time_lapse = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
            } else if (this.tarrif == "monthly") {
                time_lapse = new Date(
                    Date.now() + 30 * 24 * 60 * 60 * 1000
                );
            } else if (this.tarrif == "annually") {
                time_lapse = new Date(
                    Date.now() + 90 * 24 * 60 * 60 * 1000
                );
            } else {
                if (this.tarrif == "daily"){
                    time_lapse = new Date(
                        Date.now() + 1 * 24 * 60 * 60 * 1000);
                }else if (this.tarrif == "highroller"){
                        time_lapse = new Date(
                            Date.now() + 1 * 24 * 60 * 60 * 1000);
                }
            }

            const final_lapse = time_lapse.getTime();
            console.log(final_lapse);

            // retrieve localstorage
            let my_status = localStorage.getItem("saved_tarrif");

            if (my_status != null) {
                let mydb = ''
                if (this.tarrif == "daily") {
                    mydb = 'dailysubscriptions/'
                } 
                else if(this.tarrif == "highroller"){
                    mydb = 'highroller_subscriptions/'
                }
                {
                    mydb = 'subscriptions/'
                }
                set(ref(db, `${mydb}` + transaction_id), {
                    userid: userid,
                    transaction_id: transaction_id,
                    flw_ref: flw_ref,
                    subscription_plan: this.tarrif,
                    start_date: todaysTimeStamp,
                    end_date: final_lapse,
                }).then(() => {
                    console.log("iko sawa");
                    this.$router
                        .replace({
                            name: "Home",
                        })
                        .then(() => {
                            const toast = useToast();
                            toast.success(
                                "You have successfully subscribed to our VIP Package.",
                                {
                                    timeout: 3000,
                                }
                            );
                            localStorage.removeItem("saved_tarrif");
                        });
                });
            } else {
                this.$router
                    .replace({
                        name: "Home",
                    })
                    .then(() => {
                        const toast = useToast();
                        toast.error(
                            "Transaction Failed Please try again.",
                            {
                                timeout: 3000,
                            }
                        );
                        localStorage.removeItem("saved_tarrif");

                    });
            }


        }
    },
}
</script>
<style lang="">

</style>
