<template lang="">
    <div class="jumbotron mx-4 mt-4">
        <div class="row">
            <div class="col-12">
                    <div class="form-row">
                        <div class="col-md-4 mb-3">
                            <label for="validationCustom01">First name</label>
                            <input @change="removeerrorclass" type="text" id="first_name" class="form-control"  v-model="first_name">
                        </div>
        
                        <div class="col-md-4 mb-3">
                            <label for="validationCustom02">Last name</label>
                            <input  @change="removeerrorclass" type="text" id="last_name" class="form-control"   v-model="last_name">
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="validationCustom02">Email</label>
                            <input  @change="removeerrorclass" type="email" id="email_address" class="form-control"  v-model="email_address">
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="validationCustom02">Phone Number</label>
                            <vue-tel-input @input="executedInput" v-model="phone" class="" mode="international"></vue-tel-input>
                            <!-- <input   type="number" id="phone_number" class="form-control"  v-model="phone_number"> -->
                        </div>
                       
                    </div>
                    <button @click.prevent="submitNewsLetter" class="btn btn-success float-right">Subscribe To Updates</button>


            </div>
        </div>
        
    </div>
</template>
<script>
import { useToast } from "vue-toastification";

import { db } from "../db";
import {
    ref,
    set,
    query,
    onValue,
    orderByChild,
    equalTo,
    limitToLast,
    endBefore,
    endAt,
    startAfter,
} from "firebase/database";

export default {
     data() {
        return {
            first_name: '',
            last_name: '',
            email_address: '',
            phone: null
        }
    },
    methods: {
        executedInput(x, obj){
            this.phone = obj.number
        },
        removeerrorclass(){
            document.getElementById('first_name').classList.remove('is-invalid')
            document.getElementById('last_name').classList.remove('is-invalid')
            document.getElementById('email_address').classList.remove('is-invalid')
            // document.getElementById('phone_number').classList.remove('is-invalid')
        },
        submitNewsLetter(){
            
            console.log(this.phone);
            if(this.phone == '' || this.phone == null){
                // document.getElementById('phone_number').classList.add('is-invalid')
                const toast = useToast();
                toast.error('Phone number is required')
            }
            if(this.first_name == '' || this.last_name == '' || this.email_address == '' || this.phone == ''){
                const toast = useToast();

                if(this.first_name == ''){
                    document.getElementById('first_name').classList.add('is-invalid')
                }
                if(this.last_name == ''){
                    document.getElementById('last_name').classList.add('is-invalid')
                }
                if(this.email_address == ''){
                    document.getElementById('email_address').classList.add('is-invalid')
                }

                toast.error(
                    "All the fields are mandatory.",
                    {
                        timeout: 3000,
                    }
                );
            }else{

                let mydb = 'UserData'
                set(ref(db, `${mydb}`), {
                    email: this.email_address,
                    firstName: this.first_name,
                    lastName: this.last_name,
                    phone:  this.phone,
                    userId: localStorage.getItem("uid"),
                }).then(() => {
                    this.$router
                        .replace({
                            name: "Home",
                        })
                        .then(() => {
                            const toast = useToast();
                            toast.success(
                                "You have successfully subscribed to our Newletter.",
                                {
                                    timeout: 3000,
                                }
                            );
                        });
                });

            }


        },
    },

}
</script>
<style></style>