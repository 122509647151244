<template>
  <div>
  <div class="row" v-if="show_loading">
    <div class="col-12 text-center mt-4">
      <div class="jumbotron mx-3">
        <div class="spinner-border" role="status" >
          <span class="sr-only">Loading...</span>
        </div>
  
        <h4 class="mt-3">
          Please wait a moment as we redirect you to the checkout page ...
        </h4>
        <p class="text-danger">
          Don't close this page!
        </p>
      </div>



    </div>
  </div>
  <div v-if="!show_loading">

 
    <div class="container-fluid d-none d-lg-block my-4">
      <div class="row">
        <div class="col-md-4 mb-4">
          <div class="card-mine card_red text-center">
            <div class="title">
              <i class="fas fa-7"></i>
              <h2>Weekly</h2>

              <!-- <button @click="launchFlutterWave"></button> -->
            </div>
            <div class="price">
              <h4><i class="icon-arrow-down15 icon-4x"></i></h4>
            </div>
            <div class="option">
              <ul>
                <li>
                  By Subscribing you get access to premium predictions for one
                  week
                </li>
                <li>
                  <h2 style="
                      font-weight: 700;3000
                      color: white;
                      font-size: 40px !important;
                    ">
                    $10
                  </h2>
                </li>
              </ul>
            </div>
            <!-- @click="$router.push({
                name: 'ipay',
                params: {
                  tarrif: 'weekly',
                },
              })" -->
            <a v-if="user.loggedIn" @click="openPaymentModal('weekly')" class="projects scroll-to-top rounded">Unlock</a>

            <router-link v-else to="Signin" class="projects scroll-to-top rounded">Unlock</router-link>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="card-mine card_violet text-center">
            <div class="title">
              <i class="fas fa-3" aria-hidden="true"></i>
              <i class="fas fa-0" aria-hidden="true"></i>

              <h2>Monthly</h2>
            </div>
            <div class="price">
              <h4><i class="icon-arrow-right15 icon-4x"></i></h4>
            </div>
            <div class="option">
              <ul>
                <li>
                  By Subscribing you get access to premium predictions for one
                  Month
                </li>
                <li>
                  <h2 style="
                      font-weight: 700;
                      color: white;
                      font-size: 40px !important;
                    ">
                    $35
                  </h2>
                </li>
              </ul>
            </div>

            <a v-if="user.loggedIn" @click="openPaymentModal('monthly')" class="projects scroll-to-top rounded">Unlock</a>
            <router-link v-else to="Signin" class="projects scroll-to-top rounded">Unlock</router-link>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card-mine card_three text-center">
            <div class="title">
              <i class="fas fa-3" aria-hidden="true"></i>
              <h2>Months</h2>
            </div>
            <div class="price">
              <h4><i class="icon-arrow-up15 icon-4x"></i></h4>
            </div>
            <div class="option">
              <ul>
                <li>
                  By Subscribing you get access to premium predictions for three
                  months
                </li>
                <li>
                  <h2 style="
                      font-weight: 700;
                      color: white;
                      font-size: 40px !important;
                    ">
                    $60
                  </h2>
                </li>
              </ul>
            </div>

            <a v-if="user.loggedIn" @click="openPaymentModal('annually')"
              class="projects scroll-to-top rounded">Unlock</a>
            <router-link v-else to="Signin" class="projects scroll-to-top rounded">Unlock</router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- for mobiles -->
    <div class="container-fluid d-lg-none">
      <div class="row">
        <div class="col-12">
          <div class="card my-4" style="border-left: solid 3px teal">
            <div class="card-body text-center">
              <h6>
                <i class="fas fa-crown mr-2" style="font-size: 22px"></i> Choose
                a Subscription Plan
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3" @click="user.loggedIn ? openPaymentModal('weekly') : $router.push({ 'name': 'Signin' })">
        <div class="col-12">
          <div class="card" style="
              background: linear-gradient(45deg, #715633bd, #543404);
              color: #fff;
            ">
            <div class="card-body" style="padding: 0.55rem !important">
              <div class="row">
                <div class="col-2 text-center">
                  <center>
                    <h6 class="my-3" style="
                        width: 30px;
                        height: 30px;
                        border: solid 2px #fff;
                        border-radius: 50%;
                        padding-top: 5px;
                      ">
                      7
                    </h6>
                  </center>
                </div>
                <div class="col-7 text-center">
                  <h6 style="padding-top: 18px; font-size: 14px">
                    Weekly Subscriptions
                  </h6>
                </div>
                <div class="col-3 text-center">
                  <h6 style="padding-top: 18px">$10</h6>
                </div>

                <div class="col-12"></div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <span>
                    <small style="font-size: 9px">
                      By subscribing you get access to premium predictions for
                      one week.
                    </small>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3" @click="user.loggedIn ? openPaymentModal('monthly') : $router.push({ 'name': 'Signin' })">
        <div class="col-12">
          <div class="card" style="background:linear-gradient(45deg,grey, rgb(53, 53, 53); color: #fff;">
            <div class="card-body" style="padding: 0.55rem !important">
              <div class="row">
                <div class="col-2 text-center">
                  <center>
                    <h6 class="my-3" style="
                        width: 30px;
                        height: 30px;
                        border: solid 2px #fff;
                        border-radius: 50%;
                        padding-top: 4px;
                      ">
                      30
                    </h6>
                  </center>
                </div>
                <div class="col-7 text-center">
                  <h6 style="padding-top: 18px; font-size: 14px">
                    Monthly Subscriptions
                  </h6>
                </div>
                <div class="col-3 text-center">
                  <h6 style="padding-top: 18px">$35</h6>
                </div>

                <div class="col-12"></div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <span>
                    <small style="font-size: 9px">
                      By subscribing you get access to premium predictions for
                      one Month.
                    </small>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3" @click="user.loggedIn ? openPaymentModal('annually') : $router.push({ 'name': 'Signin' })">
        <div class="col-12">
          <div class="card" style="
              background: linear-gradient(45deg, gold, #543404);
              color: #fff;
            ">
            <div class="card-body" style="padding: 0.55rem !important">
              <div class="row">
                <div class="col-2 text-center">
                  <center>
                    <h6 class="my-3" style="
                        width: 30px;
                        height: 30px;
                        border: solid 2px #fff;
                        border-radius: 50%;
                        padding-top: 5px;
                      ">
                      3
                    </h6>
                  </center>
                </div>
                <div class="col-7 text-center">
                  <h6 style="padding-top: 18px; font-size: 14px">
                    3 Months Subscriptions
                  </h6>
                </div>
                <div class="col-3 text-center">
                  <h6 style="padding-top: 18px">$60</h6>
                </div>

                <div class="col-12"></div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <span>
                    <small style="font-size: 9px">
                      By subscribing you get access to premium predictions for
                      one year.
                    </small>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-md " role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title text-center" id="exampleModalLabel">
              <h3 style="font-weight: 800;">
                Choose a CheckOut Method
              </h3>
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="card" @click="selectRadio('mobilemoney')" style="cursor: pointer;">
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <img src="https://cdn-icons-png.flaticon.com/512/6811/6811723.png" class="img-responsive mr-3" style="height: 70px;" />
                        Mobile Money
                      </div>

                      <div>
                        
                        <div v-if="selectedOption == 'mobilemoney'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mt-4" @click="selectRadio('stripe')" style="cursor: pointer;">
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <img src="../assets/images/stripe.webp" class="img-responsive" style="height: 50px;" />

                        Stripe
                      </div>

                      <div>
                    

                        <div v-if="selectedOption == 'stripe'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>

                      </div>
                    </div>
                  </div>
                </div>


                <div class="card mt-4" @click="selectRadio('crypto')" style="cursor: pointer;">
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <img src="../assets/images/bitcoin.webp" class="img-responsive" style="height: 80px;" />

                        Crypto
                      </div>

                      <div>
 
                        <div v-if="selectedOption == 'crypto'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>

                      </div>
                    </div>
                  </div>
                </div>

                <button class="btn btn-info btn-block my-4" @click="proceedToCheckout">Proceed <i
                    class="fas fa-arrow-right ml-1"></i></button>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" id="countryModal" tabindex="-1" role="dialog" aria-labelledby="countryModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-md " role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title text-center" id="countryModalLabel">
              <h3 style="font-weight: 800;">
                Choose a Country
              </h3>              
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="height: 500px; overflow-y: auto; overflow-x: hidden;">
            <div> 
              <div class="row">
                <div class="col-12">
                  <div class="card" @click="selectCountry('kenya')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="https://cdn-icons-png.flaticon.com/512/3955/3955604.png" class="img-responsive" style="height: 70px;" />
                          Kenya
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'kenya'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
  
                  <div class="card mt-4" @click="selectCountry('Nigeria')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="   https://cdn-icons-png.flaticon.com/512/5327/5327538.png " class="img-responsive mr-4" style="height: 50px;" />
                          Nigeria
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'Nigeria'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="card mt-3" @click="selectCountry('Ghana')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="https://cdn-icons-png.flaticon.com/512/555/555424.png " class="img-responsive mr-4" style="height: 50px;" />
                          Ghana
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'Ghana'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="card mt-3" @click="selectCountry('Uganda')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="   https://cdn-icons-png.flaticon.com/512/555/555670.png " class="img-responsive mr-4" style="height: 50px;" />
                          Uganda
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'Uganda'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="card mt-3" @click="selectCountry('Rwanda')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="   https://cdn-icons-png.flaticon.com/512/555/555436.png " class="img-responsive mr-4" style="height: 50px;" />
                          Rwanda
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'Rwanda'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="card mt-3" @click="selectCountry('Zambia')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="   https://cdn-icons-png.flaticon.com/512/555/555663.png " class="img-responsive mr-4" style="height: 50px;" />
                          Zambia
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'Zambia'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="card mt-3" @click="selectCountry('Tanzania')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="   https://cdn-icons-png.flaticon.com/512/206/206846.png " class="img-responsive mr-4" style="height: 50px;" />
                          Tanzania
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'Tanzania'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>

         

                  <div class="card mt-3" @click="selectCountry('XAF')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <img src="   https://cdn-icons-png.flaticon.com/512/3492/3492623.png " class="img-responsive mr-4" style="height: 50px;" />
                        <div class="d-flex flex-column">
                          <p><b>Francophone mobile money(XAF) </b></p>
                          Cameroon, Central African Republic, Chad, Republic of the Congo, Equatorial Guinea and Gabon
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'XAF'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mt-3" @click="selectCountry('XOF')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <img src="   https://cdn-icons-png.flaticon.com/512/1959/1959169.png " class="img-responsive mr-4" style="height: 50px;" />
                        <div class="d-flex flex-column">
                          <p><b>Francophone mobile money (XOF) </b></p>
                          Benin, Burkina Faso, Côte d'Ivoire, Guinea-Bissau, Mali, Niger, Senegal and Togo
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'XOF'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="card mt-3" @click="selectCountry('Other')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="    https://cdn-icons-png.flaticon.com/512/9985/9985721.png  " class="img-responsive mr-4" style="height: 50px;" />
                          Other
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'Other'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
  
  
                </div>            
              </div>
            </div>

            <button class="btn btn-primary btn-block my-4" @click="proceedToCheckoutMobileMoney">Proceed <i
                    class="fas fa-arrow-right ml-1"></i></button>


          </div>
        </div>
      </div>
    </div>
  </div>
    
  </div>
</template>

<script>
import { db } from "../db";
import {
  ref,
  set,
  query,
  onValue,
  orderByChild,
  equalTo,
  limitToLast,
  endBefore,
  endAt,
  startAfter,
} from "firebase/database";
import { Timestamp, serverTimestamp } from "@firebase/firestore";
import store from "../store";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import paystack from "vue3-paystack";
import axiosInst from "../services/api";
import { useToast } from "vue-toastification";
import {useFlutterwave} from "flutterwave-vue3"

export default {
  name: "Subscribe",
  components: {
    paystack,
  },
  
  data() {
    return {
      publishableKey : 'pk_live_51N0kEvLz2kuZb2bS42azXJD4hJ9n07Dc55NbJkTOmiWrAmTmgvH6qI4uAKlBBlAlMxxUPRJ7jAAHj3F3TS2SPUGv00sOc30tsk',
      tel: "",
      is_processing_payment: false,
      show_loading: false,
      token: '',
      transaction_failed: false,
      status_msg:
        "A Pop up has been sent to your Phone. Enter your PIN and do not leave this page, this may take a few seconds..",
      duration: "",
      saved_oid: "",
      selectedOption: 'mobilemoney',
      selectedCountry: 'kenya',
      currencyToUse: 'USD',
      saved_tarrif: '',
      kesdolar: 1,
      amountForFlutterwave: 1,
      weeklyamount: 1,
      monthlyamount: 4025,
      checkoutRef: null,
      loading: false,
      stripe: null,
      yearlyamount: 6900,

      lineItemsWeekly: [
        {
          price: 'price_1NOC1DLz2kuZb2bSLGcSQvRD', // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      lineItemsMonthly: [
        {
          price: 'price_1NOC0bLz2kuZb2bS24dzyN6n', // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      lineItemsAnnually: [
        {
          price: 'price_1NOC1dLz2kuZb2bSUtnmGl9s', // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],

      successURL1: 'https://thevipbettingtips.com/#/stripe-1?tarrif=weekly',
      successURL2: 'https://thevipbettingtips.com/#/stripe-1?tarrif=monthly',
      successURL3: 'https://thevipbettingtips.com/#/stripe-1?tarrif=annually',
      cancelURL: 'https://thevipbettingtips.com/#/Subscribe',
    };
  },



  methods: {

    launchFlutterWave(){
      useFlutterwave({
        amount: this.amountForFlutterwave,//amount
        callback() {
          //  TODO: handle callbacks
        },
        currency:  this.currencyToUse,
        customer: {email: localStorage.getItem("email"), name: '', phone_number: ''},
        customizations: {description: "Pay Now", logo: "", title: "VipBettingTips"},
        meta: {
          user_id: localStorage.getItem("uid"),
          tarrif: this.saved_tarrif
        },
        onclose() {

        },
        payment_options:  "card,mpesa,ussd,banktransfer,mobilemoneyghana,nqr,mobilemoneyuganda,mobilemoneyrwanda,mobilemoneyzambia,barter,credit,account,mobilemoneyfranco,mobilemoneytanzania",
        public_key: "FLWPUBK-c6e9f068abe9b2f556e9b5071210563e-X",
        redirect_url: "https://thevipbettingtips.com/#/flutterwave",
        tx_ref: this.generateReference()
      })
    },
    proceedToCheckout() {

      console.log(this.selectedOption)
      this.show_loading = true
      
      $("#exampleModal").modal("hide");
      if (this.selectedOption == 'mobilemoney') {
        this.show_loading = false
        $("#countryModal").modal("show");
      } 
      else if (this.selectedOption == 'crypto'){

          let formData2
          let date = new Date()
          let orderId = date.getTime().toString()

          if(this.saved_tarrif == 'weekly'){
            formData2 = {
              "price_amount": 10,
              "price_currency": "usd",
              "order_id": orderId,
              "order_description": "By Subscribing you get access to premium predictions for one week",
              "ipn_callback_url": "https://nowpayments.io",
              "success_url": "https://thevipbettingtips.com/#/stripe-1?tarrif=weekly",
              "cancel_url": "https://thevipbettingtips.com/#/Subscribe",
              "partially_paid_url": "https://thevipbettingtips.com/#/stripe-1?tarrif=weekly",
              "is_fixed_rate": true,
              "is_fee_paid_by_user": false
            }
          }else if(this.saved_tarrif == 'monthly'){
            formData2 = {
              "price_amount": 35,
              "price_currency": "usd",
              "order_id": orderId,
              "order_description": "By Subscribing you get access to premium predictions for one week",
              "ipn_callback_url": "https://nowpayments.io",
              "success_url": "https://thevipbettingtips.com/#/stripe-1?tarrif=monthly",
              "cancel_url": "https://thevipbettingtips.com/#/Subscribe",
              "partially_paid_url": "https://thevipbettingtips.com/#/stripe-1?tarrif=monthly",
              "is_fixed_rate": true,
              "is_fee_paid_by_user": false
            }

          }else if(this.saved_tarrif == 'annually'){
             formData2 = {
              "price_amount": 35,
              "price_currency": "usd",
              "order_id": orderId,
              "order_description": "By Subscribing you get access to premium predictions for one week",
              "ipn_callback_url": "https://nowpayments.io",
              "success_url": "https://thevipbettingtips.com/#/stripe-1?tarrif=annually",
              "cancel_url": "https://thevipbettingtips.com/#/Subscribe",
              "partially_paid_url": "https://thevipbettingtips.com/#/stripe-1?tarrif=annually",
              "is_fixed_rate": true,
              "is_fee_paid_by_user": false
            }

          }
          
          axios.defaults.headers['X-API-KEY'] = 'RAQ7EHX-M57M2NE-JPETSSB-5FT4F53';
          axios.defaults.headers['Content-Type'] = 'application/json';
          axios.post('https://api.nowpayments.io/v1/invoice', formData2).then((res)=>{
            console.log(res)  
            let redirect_url = res.data.invoice_url
            localStorage.setItem("PaymentLegit", true);            
            window.location.href = redirect_url                      
          })


      }
      else {

        localStorage.setItem("PaymentLegit", true);

        if(this.saved_tarrif == 'weekly'){
          this.stripe.redirectToCheckout({
            successUrl: this.successURL1,
            cancelUrl: 'https://thevipbettingtips.com/#/Subscribe',
            lineItems: this.lineItemsWeekly,
            mode: "payment"
          })
        }else if(this.saved_tarrif == 'monthly'){
          this.stripe.redirectToCheckout({
            successUrl: this.successURL2,
            cancelUrl: 'https://thevipbettingtips.com/#/Subscribe',
            lineItems: this.lineItemsMonthly,
            mode: "payment"
          })
        }else{
          this.stripe.redirectToCheckout({
            successUrl: this.successURL3,
            cancelUrl: 'https://thevipbettingtips.com/#/Subscribe',
            lineItems: this.lineItemsAnnually,
            mode: "payment"
          })
        }

               

      }

    },

    async triggerMoneyCompute(){
      if(this.saved_tarrif == 'weekly'){
        await this.convertCurrency(10, 'USD', this.currencyToUse)
      }else if(this.saved_tarrif == 'monthly'){
        await this.convertCurrency(35, 'USD', this.currencyToUse)
      }else if(this.saved_tarrif == 'annually'){
        await this.convertCurrency(60, 'USD', this.currencyToUse)
      }

    },

    async proceedToCheckoutMobileMoney(){
      if(this.selectedCountry == 'kenya'){
        this.currencyToUse = 'KES'
      }else if (this.selectedCountry == 'Nigeria'){
        this.currencyToUse = 'NGN'
      }else if (this.selectedCountry == 'Ghana'){
        this.currencyToUse = 'GHS'
      }else if (this.selectedCountry == 'Uganda'){
        this.currencyToUse = 'UGX'
      }else if (this.selectedCountry == 'Rwanda'){
        this.currencyToUse = 'RWF'
      }else if (this.selectedCountry == 'Zambia'){
        this.currencyToUse = 'ZMW'
      }else if (this.selectedCountry == 'Tanzania'){
        this.currencyToUse = 'TZS'
      }else if (this.selectedCountry == 'Other'){
        this.currencyToUse = 'USD'
      }else if (this.selectedCountry == 'XAF'){
        this.currencyToUse = 'XAF'
      }else if (this.selectedCountry == 'XOF'){
        this.currencyToUse = 'XOF'
      }

      $("#countryModal").modal("hide");

      await this.triggerMoneyCompute()

      localStorage.setItem("saved_tarrif", this.saved_tarrif)

      setTimeout(() => {

        console.log(this.amountForFlutterwave)

        // this.amountForFlutterwave = 1
        
        this.launchFlutterWave()
      }, 500); 

    },
    selectRadio(value) {
      this.selectedOption = value;
    },
    selectCountry(value) {
      this.selectedCountry = value;
    },
    async convertCurrency(amount, fromCurrency, toCurrency, cb) {
      var apiKey = "50507e1e79cbb52820dc";
      fromCurrency = encodeURIComponent(fromCurrency);
      toCurrency = encodeURIComponent(toCurrency);
      var query = fromCurrency + "_" + toCurrency;

      var url =
        "https://free.currconv.com/api/v7/convert?q=" +
        query +
        "&compact=ultra&apiKey=" +
        apiKey;
      await axios
        .get(url)
        .then((response) => {
          console.log(response)
          let { data } = response;
          let tray = data[`USD_${toCurrency}`];

          console.log(tray * amount)

          this.amountForFlutterwave = tray * amount;
        })
        .catch((error) => {
          throw error;
        });
    },


    generateReference() {
      let date = new Date();
      return date.getTime().toString();
    },

    openPaymentModal(duration) {
      $("#exampleModal").modal("show");
      this.saved_tarrif = duration
      // this.duration = duration;
    },

    async payMpesa(method) {
      console.log(this.duration);
      if (this.tel == null || this.tel == "") {
        const toast = useToast();
        toast.error(
          "You must enter a phone number.",
          {
            timeout: 3000,
          });
        return false;
      }

      let formData;

      if (this.duration == "weekly") {
        formData = {
          amount: "1200",
          tel: this.tel,
        };
      } else if (this.duration == "monthly") {
        formData = {
          amount: "4025",
          tel: this.tel,
        };
      } else if (this.duration == "annually") {
        formData = {
          amount: "6900",
          tel: this.tel,
        };
      }

      this.status_msg =
        "A Pop up has been sent to your Phone. Enter your PIN and do not leave this page, this may take a few seconds..";

      this.is_processing_payment = true;
      this.transaction_failed = false;
      $("#mpesa_btn").addClass("disabled");
      $("#mpesa_btn_spinner").addClass("spinner-border ");
      let url
      if (method == 'mpesa') {
        url = '/payment/stk_mpesa'
      } else if (method == 'airtel') {
        url = '/payment/stk_airtell'
      }
      axiosInst
        .post(`/payment/stk_mpesa`, formData)
        .then((response) => {
          let { data } = response;
          console.log(data);
          this.saved_oid = data.oid;

          setTimeout(() => {
            const searchData = {
              oid: data.oid,
            };
            this.status_msg =
              "searching for your transaction in our database...";
            axiosInst
              .post(`/payment/search`, searchData)
              .then((response) => {
                let { data } = response;
                console.log(data);

                if (data.header_status == 404) {
                  this.is_processing_payment = false;
                  $("#mpesa_btn").removeClass("disabled");
                  $("#mpesa_btn_spinner").removeClass("spinner-border ");
                  this.transaction_failed = true;
                } else if (data.header_status == 200) {
                  this.is_processing_payment = false;
                  $("#mpesa_btn").removeClass("disabled");
                  $("#mpesa_btn_spinner").removeClass("spinner-border ");

                  console.log(data);

                  const userid = localStorage.getItem("uid");
                  const transaction_id = data.data.transaction_code;
                  const flw_ref = data.data.oid;
                  const todaysTimeStamp = new Date().getTime();
                  let time_lapse;

                  if (this.duration == "weekly") {
                    time_lapse = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
                  } else if (this.duration == "monthly") {
                    time_lapse = new Date(
                      Date.now() + 30 * 24 * 60 * 60 * 1000
                    );
                  } else if (this.duration == "annually") {
                    time_lapse = new Date(
                      Date.now() + 90 * 24 * 60 * 60 * 1000
                    );
                  }

                  console.log(time_lapse);

                  const final_lapse = time_lapse.getTime();

                  set(ref(db, "subscriptions/" + transaction_id), {
                    userid: userid,
                    transaction_id: transaction_id,
                    flw_ref: flw_ref,
                    subscription_plan: this.duration,
                    start_date: todaysTimeStamp,
                    end_date: final_lapse,
                  }).then(() => {
                    console.log("iko sawa");
                    $("#exampleModal").modal("hide");
                    this.$router
                      .replace({
                        name: "Home",
                      })
                      .then(() => {
                        const toast = useToast();
                        toast.success(
                          "You have successfully subscribed to our VIP Package.",
                          {
                            timeout: 3000,
                          }
                        );
                      });
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }, 30000);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    retrySearch() {
      const searchData = {
        oid: this.saved_oid,
      };
      this.is_processing_payment = true;
      $("#tryagainbtn").addClass("disabled");
      $("#try_again_spinner").addClass("spinner-border");
      axiosInst
        .post(`/payment/search`, searchData)
        .then((response) => {
          let { data } = response;
          console.log(data);

          if (data.header_status == 404) {
            this.is_processing_payment = false;
            $("#tryagainbtn").removeClass("disabled");
            $("#try_again_spinner").removeClass("spinner-border");
            this.transaction_failed = true;
          } else if (data.header_status == 200) {
            this.is_processing_payment = false;
            this.transaction_failed = false;
            $("#tryagainbtn").removeClass("disabled");
            $("#try_again_spinner").removeClass("spinner-border");

            console.log(data);

            const userid = localStorage.getItem("uid");
            const transaction_id = data.data.transaction_code;
            const flw_ref = data.data.oid;
            const todaysTimeStamp = new Date().getTime();
            let time_lapse;

            if (this.duration == "weekly") {
              time_lapse = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
            } else if (this.duration == "monthly") {
              time_lapse = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
            } else if (this.duration == "annually") {
              time_lapse = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
            }

            console.log(time_lapse);

            const final_lapse = time_lapse.getTime();

            set(ref(db, "subscriptions/" + transaction_id), {
              userid: userid,
              transaction_id: transaction_id,
              flw_ref: flw_ref,
              subscription_plan: "monthly",
              start_date: todaysTimeStamp,
              end_date: final_lapse,
            }).then(() => {
              console.log("iko sawa");
              $("#exampleModal").modal("hide");
              this.$router
                .replace({
                  name: "Home",
                })
                .then(() => {
                  const toast = useToast();
                  toast.success(
                    "You have successfully subscribed to VIP Package.",
                    {
                      timeout: 3000,
                    }
                  );
                });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  computed: {
    ...mapGetters({
      user: "user",
      subscription: "subscription",
      displayCrusials: "displayCrusials",
    }),
  },

  created() {
    store.dispatch("setvisibility", true);
  },
  async mounted(){
    localStorage.removeItem("PaymentLegit");
    this.stripe = Stripe(this.publishableKey)
  }   
};









//FLWPUBK-aa8d7c4b6806c056c211682bbcae422e-X

//public key

//FLWPUBK_TEST-c9b04fc1029f1616e3da641e7cbd8183-X


//secret key

//FLWSECK_TEST-4067efef66a2f820ba3fadd3d8096ca2-X

//encryption key

//FLWSECK_TESTc4925a9f09db
</script>

<style scoped src="@/assets/style.css">
/* @import "../assets/style.css"; */

.custom-control-input {
  /* Increase the size of radio buttons */
  width: 1.5rem;
  height: 1.5rem;
}




</style>