<template>
    <div>
        spiner loader
    </div>
</template>


<script>
export default {
    name: "Loading",
    components: {

    },
    
}
</script>


<style lang="">
    
</style>