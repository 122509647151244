<template>
    <div class="news">    
        <div class="jumbotron mx-2 pt-2 px-2 my-4 jumbotron-bg" >
            <div id="horizontal-list" class="d-flex flex-row align-items-center overflow-auto my-4">
                <button class="btn btn-md m-1 text-nowrap" @click="selected_category = ''" :class="selected_category == '' ? 'btn-info' : 'btn-dark'">
                    All
                </button>
                <button  v-for="category in vipCategories"  class="btn btn-sm btn-m-1 text-nowrap" :class="selected_category == category ? 'btn-info' : 'btn-dark'" @click="selected_category = category">
                    <img :src="returnImgFromTag(category)" style="height: 22px; margin-right: 10px;"/>
                    {{ categoryNameFromCode(category) }}
                </button>
                <!-- Add more chips as needed -->
                
            </div> 

            <div class="row">
                <div class="col-md-8 offset-md-2 col-12">
                    <div class="card-overlay">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <img src="../assets/images/premium.webp" class="img-responsive" style="height:140px"/>

                                    <h3 class="mt-4" style="color: rgb(198, 130, 2);">PREMIUM CONTENT</h3>
                                    <h5 class="mt-4" style="color: rgb(198, 130, 2);;">Purchase a subscription to get access to premium tips</h5>
                                    <h6 class="mt-4" style="color: #fff;">Visit the shop now to subscribe to a package</h6>

                                    <button @click="$router.push({'name': 'Subscribe'})" class="btn mt-4" style="background:rgb(202, 132, 1);!important; font-size: 20px; font-weight: 900; color:white;">VISIT STORE</button>
                                </div>


                            </div>
                            
                        </div>
                    </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-md-8 offset-md-2 col-12 text-center">
                            <button class="btn btn-md" @click="$router.push({'name': 'newsletter'})" style="background-color: white!important; font-size: 20px;"><i class="fas fa-envelope mr-1" ></i> Newsletter</button>
                </div>
            </div>

        </div>




    </div>
</template>

<script>

import { db } from "../db";
import {
    ref,
    set,
    query,
    onValue,
    orderByChild,
    equalTo,
    limitToLast,
    endBefore,
    endAt,
    startAfter,
} from "firebase/database";
import store from "../store";
import { mapGetters, mapActions } from "vuex";

export default {




    name: "PremiumContent",
    components: {
    },

    data() {

        return {
            vipCategories: [],
            sportsCategories: [
                { name: 'Football', code: 'fb' },
                { name: 'Basketball', code: 'bb' },
                { name: 'Tennis', code: 'tn' },
                { name: 'Horse Racing', code: 'hr' },
                { name: 'Snooker', code: 'sn' },
                { name: 'Rugby', code: 'ru' },
                { name: 'American Football', code: 'af' },
                { name: 'Cricket', code: 'cr' },
                { name: 'Athletics', code: 'at' },
                { name: 'Boxing', code: 'bx' },
                { name: 'Handball', code: 'hb' },
                { name: 'Ice Hockey', code: 'ih' },
                { name: 'Volleyball', code: 'vb' },
                { name: 'Baseball', code: 'ba' },
                { name: 'Aussie Rules', code: 'ar' },
                { name: 'Cycling', code: 'cy' },
                { name: 'E Sports', code: 'es' },
                { name: 'Table Tennis', code: 'tt' },
                { name: 'Mixed Martial Arts', code: 'mma' },
                { name: 'Darts', code: 'da' },
                { name: 'Water Polo', code: 'wp' }
            ],
            selected_category: '',
           
        }
    },
    mounted() {
        const horizontalList = document.getElementById('horizontal-list');

        horizontalList.addEventListener('wheel', (event) => {
            event.preventDefault();
            const scrollAmount = event.deltaY;
            horizontalList.scrollLeft += scrollAmount;
        });


       


    },

   

    methods: {

        categoryNameFromCode(code) {
            try{
                return this.sportsCategories.find(x => x.code == code).name
            }catch(e){
                return code
            }
        },

         returnImgFromTag(tag) {

            if (tag == 'fb') {
                return require("@/assets/images/footballimage.webp");
            } else if (tag == 'bb') {
                return require("@/assets/images/basketball.webp");
            } else if (tag == 'tn') {
                return require("@/assets/images/tennis.webp");
            } else if (tag == 'hr') {
                return require("@/assets/images/horserace.webp");
            } else if (tag == 'ru') {
                return require("@/assets/images/rugby.webp");
            } else if (tag == 'af') {
                return require("@/assets/images/americanfb.webp");
            } else if (tag == 'at') {
                return require("@/assets/images/athlete.webp");
            }
            else if (tag == 'bx') {
                return require("@/assets/images/boxing.webp");
            }
            else if (tag == 'hb') {
                return require("@/assets/images/handball.webp");
            }
            else if (tag == 'ih') {
                return require("@/assets/images/icehokey.webp");
            }
            else if (tag == 'vb') {
                return require("@/assets/images/voleyball.webp");
            }
            else if (tag == 'sn') {
                return require("@/assets/images/snooker.webp");
            }
            else if (tag == 'cr') {
                return require("@/assets/images/cricket.webp");
            }
            else if (tag == 'ba') {
                return require("@/assets/images/baseball.webp");
            }
            else if (tag == 'ar') {
                return require("@/assets/images/assie-rule.webp");
            }
            else if (tag == 'cy') {
                return require("@/assets/images/cycling.webp");
            }
            else if (tag == 'es') {
                return require("@/assets/images/boxing.webp");
            }
            else if (tag == 'tt') {
                return require("@/assets/images/tt.webp");
            }
            else if (tag == 'mma') {
                return require("@/assets/images/martialarts.webp");
            }
            else if (tag == 'da') {
                return require("@/assets/images/dart.webp");
            }
            else if (tag == 'wp') {
                return require("@/assets/images/waterpollo.webp");
            }

        },

        
    },
    watch: {
       
    },

    created(){
        const starCountRef = ref(db, "vip_betting_predictions");
        onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        let messages = [];
        this.vipCategories = []
        this.is_loading_tips = true
        Object.keys(data).forEach((key) => {
            messages.push({
            id: key,
            league: data[key].league,
            bet_title: data[key].bet_title,
            odd: data[key].odd,
            outcome: data[key].outcome,
            prediction: data[key].prediction,
            team_one_name: data[key].team_one_name,
            team_two_name: data[key].team_two_name,
            tag: data[key].tag,
            reason_for_tip: data[key].reason_for_tip,
            });

            if (!this.vipCategories.includes(data[key].tag)) {
                this.vipCategories.push(data[key].tag)
            }
        });
    })
    }



}
</script>

<style scoped>

.overflow-auto {
    overflow-x: auto;
    white-space: nowrap;
}

.overflow-auto::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.jumbotron-bg {
  background-image: url('../assets/images/blog-1.jpg');
  background-size: cover;
  background-position: center;
  
}

.card-overlay {
  position: relative;
}

.card-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(4px); /* Add blur effect to the overlay */

  z-index: 1;
}

.card {
  background-color: transparent;
  position: relative;
  z-index: 2;
}

</style>