<template>
  <div class="home" id="this">
    <div class="container-fluid">
      <div class="row">
        <div
          class="col-md-12 col-xl-8 d-none d-lg-block my-4"
          id="forlargerscreens"
        >
          <div
            class="card my-3"
            id="jumbotronMain"
            style="border: solid 3px #facb00; border-radius: 11px"
          >
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-12">
                  <h4 id="topbookmarkerbold">Top BookMarkers</h4>
                </div>
              </div>
              <div class="row mb-4" v-if="loading">
                <div class="col-md-2">
                  <div class="roundround skeleton"></div>
                </div>
                <div class="col-md-2 text-left" style="width: 20%">
                  <h6 class="text-muted" style="margin-top: 15px">
                    <div class="skeleton skeleton-text"></div>

                    <div class="skeleton skeleton-text"></div>
                  </h6>
                  <span>
                    <span style="color: #1b9d5e">
                      <div class="skeleton skeleton-text"></div>
                      <div class="skeleton skeleton-text"></div></span
                  ></span>
                  loading...
                </div>
                <div class="col-md-4">
                  <h5
                    style="margin-top: 15px; color: #3e1d9c; font-weight: 550"
                  >
                    <div class="skeleton skeleton-text"></div>
                    <div class="skeleton skeleton-text"></div>
                    loading...
                  </h5>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-6 text-right">
                      <div class="skeleton skeleton-btn"></div>
                    </div>
                    <div class="col-6 text-left">
                      <div class="skeleton skeleton-btn float-left"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row mb-4"
                v-for="message in documents"
                :key="message.key"
              >
                <div class="col-md-2">
                  <div class="">
                    <center>
                      <img
                        :src="message.icon"
                        class="bettingicons img-responsive"
                        alt="betway"
                      />
                     
                    </center>
                  </div>
                </div>
                <div class="col-md-2 text-left" style="width: 20%">
                  <h6 class="text-muted" style="margin-top: 15px">
                    {{ message.betname }}
                  </h6>
                  <span
                    ><i class="fa-solid fa-star" style="color: #f9c81c"></i>
                    <span style="color: #1b9d5e"> 8/10</span></span
                  >
                </div>
                <div class="col-md-4 text-left">
                  <h5
                    style="margin-top: 15px; color: #3e1d9c; font-weight: 550"
                  >
                    <i class="fa-solid fa-gift" style="color: #f9c81c"></i>
                    {{ message.description }}
                  </h5>
                </div>
                <div class="col-md-4 text-center">
                  <!-- <a href="" class="btn btn-outline-primary btn-sm" style="border-radius:5px;margin-top:15px;">Review</a> -->
                  <a
                    :href="message.link"
                    target="_blank"
                    class="btn btn-success btn-sm"
                    style="border-radius: 5px; margin-top: 15px"
                    >CLAIM BONUS</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 d-lg-none" id="forsmallerscreens">
          <div
            class="card my-3 jumbotronMain"
            style="border: solid 3px #facb00; border-radius: 11px"
          >
            <div class="card-body" style="padding-bottom: 0">
              <div class="row mb-4">
                <div class="col-12">
                  <h4 id="topbookmarkerbold">Top BookMarkers</h4>
                </div>
              </div>
              <!-- if div is loading fetching data we display this -->
              <div class="row mb-4" v-if="loading">
                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-2 col-3">
                      <div class="roundround skeleton"></div>
                    </div>

                    <!-- <div class="col-sm-3 col-3 align-middle">
                      <h6 class="text-muted align-middle" style="margin-top:25px;">
                         <div class="skeleton skeleton-text"></div>
                          <div class="skeleton skeleton-text"></div>
                      </h6>
                    </div> -->

                    
                    <div class="col-sm-7 offset-2 col-6">
                      <h6
                        style="
                          margin-top: 15px;
                          color: #3e1d9c;
                          font-weight: 550;
                        "
                      >
                        <div class="skeleton skeleton-text"></div>
                        <div class="skeleton skeleton-text"></div>
                        loading...
                      </h6>
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-6">
                      <div class="skeleton skeleton-btn"></div>
                    </div>
                    <div class="col-6">
                      <div class="skeleton skeleton-btn"></div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- end of loading this is hiddena and the next section displayed -->

              <div
                class="row mb-4"
                v-for="message in documents"
                :key="message.key"
              >
                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-2 col-3">
                      <div class="">
                        <center>
                          <img
                            :src="message.icon"
                            class="bettingicons img-responsive"
                            alt="betway"
                            style="position: relative; bottom: 5px"
                          />
                        
                        </center>
                      </div>
                    </div>

                    <div class="col-sm-10 col-9 align-middle">
                      <h6
                        class="text-muted align-middle text-nowrap"
                        style="margin-top: 0px"
                      >
                        {{ message.betname }}
                        <i
                          class="fas fa-star"
                          style="color: #f9c81c; font-size: 10px; float: right"
                          ><span
                            class="ml-1"
                            style="font-size: 10px; color: #7ba311"
                            >8 / 10</span
                          ></i
                        >
                      </h6>

                      <div class="row">
                        <div class="col-1">
                          <i
                            class="fa-solid fa-gift mr-1"
                            style="color: #f9c81c"
                          ></i>
                        </div>
                        <div class="col-10">
                          <h6
                            style="
                              margin-top: 0px;
                              color: #3e1d9c;
                              font-weight: 550;
                            "
                          >
                            {{ message.description }}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-center">
                      <a
                        :href="message.link"
                        target="_blank"
                        class="btn btn-success btn-sm btn-block"
                        style="
                          border-radius: 5px;
                          margin-top: 15px;
                          font-weight: 600;
                        "
                        >Claim Bonus</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-xl-4 my-4 d-none d-lg-block">
          <div class="card" style="background: #6597e1; border-radius: 9px">
            <div class="card-body">
              <h5 style="color: #fff; font-weight: 600">Popular categories</h5>

              <div class="row mb-2">
                <div class="col-12">
                  <div
                    class="card"
                    style="
                      background: transparent;
                      color: #fff;
                      font-weight: 700;
                      border-radius: 10px;
                    "
                  >
                    <div
                      class="card-body clickable-row pointer"
                      @click="redirectto"
                      style="border-radius: 10px"
                    >
                      <div class="row">
                        <div class="col-2">
                          <img src="../assets/images/football.svg" alt="" />
                        </div>
                        <div class="col-8 text-left" style="margin-top: 6px">
                          Football betting sites
                        </div>
                        <div class="col-1" style="margin-top: 6px">
                          <i class="fa-solid fa-chevron-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-12">
                  <div
                    class="card"
                    style="
                      background: transparent;
                      color: #fff;
                      font-weight: 700;
                      border-radius: 10px;
                    "
                  >
                    <div
                      class="card-body clickable-row pointer"
                      @click="redirectto"
                      style="border-radius: 10px"
                    >
                      <div class="row">
                        <div class="col-2">
                          <img src="../assets/images/horse-racing.svg" alt="" />
                        </div>
                        <div class="col-8 text-left" style="margin-top: 6px">
                          Horse racing betting sites
                        </div>
                        <div class="col-1" style="margin-top: 6px">
                          <i class="fa-solid fa-chevron-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-12">
                  <div
                    class="card"
                    style="
                      background: transparent;
                      color: #fff;
                      font-weight: 700;
                      border-radius: 10px;
                    "
                  >
                    <div
                      class="card-body clickable-row pointer"
                      @click="redirectto"
                      style="border-radius: 10px"
                    >
                      <div class="row">
                        <div class="col-2">
                          <img src="../assets/images/new.svg" alt="" />
                        </div>
                        <div class="col-8 text-left" style="margin-top: 6px">
                          New betting sites
                        </div>
                        <div class="col-1" style="margin-top: 6px">
                          <i class="fa-solid fa-chevron-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12">
                  <div
                    class="card"
                    style="
                      background: transparent;
                      color: #fff;
                      font-weight: 700;
                      border-radius: 10px;
                    "
                  >
                    <div
                      class="card-body clickable-row pointer"
                      @click="redirectto"
                      style="border-radius: 10px"
                    >
                      <div class="row">
                        <div class="col-2">
                          <img src="../assets/images/crypto.svg" alt="" />
                        </div>
                        <div class="col-8 text-left" style="margin-top: 6px">
                          Crypot betting sites
                        </div>
                        <div class="col-1" style="margin-top: 6px">
                          <i class="fa-solid fa-chevron-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- the middle part -->
      <div class="row">
        <div class="col-lg-9">
          <div class="row" v-if="!loading">
            <div class="col-md-4">
              <div
                class="card mb-3 slip"

              >
                <div class="card-body" style="height: 150px!important">
                  <div
                    class="row"
                    v-if="
                      dailysubscription.hasdailysubscription == false &&
                      !subscription.havesubscription
                    "
                  >
                    <div class="col-sm-12 text-center">
                      <h6>Feature slip of the day</h6>
                      <h6 v-for="odd in featurebettingtips" :key="odd.id">
                        Slip Odd {{ odd.total_odd }}
                      </h6>
                    </div>
                    <div class="col-sm-12 text-center" v-if="user.loggedIn">
                      <a
                        href=""
                        @click.prevent="openPaymentModal('daily')"
                        class="btn    btn-success btn-sm"
                        style="font-size: 13px !important; border-radius: 28px"
                        ><i class=""></i> UNLOCK $1</a
                      >
                    </div>
                    <div class="col-sm-12 text-center" v-else>
                      <router-link
                        to="/Signin"
                        class="btn btn-success btn-sm"
                        style="font-size: 13px !important; border-radius: 28px"
                        ><i class=""></i> UNLOCK $1</router-link
                      >
                    </div>
                  </div>

                  <div v-else class="text-center">
                    <router-link
                      to="/Featured"
                      class="btn btn-secondary btn-sm"
                      style="font-size: 13px !important; border-radius: 28px"
                      ><i class="fas fa-gift mr-2"></i> Preview
                      Featured</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
           


            <div class="col-md-4 mb-4">
                <div class="card featuredslip" >
                  <div class="card-body" style="height: 150px!important">

                    <!--  highrollerslipssubscription.hashighrollerslips == false &&
                      !subscription.havesubscription -->
                      <div
                    class="row"
                    v-if="
                     highrollerslipssubscription.hashighrollerslips == false &&
                      !subscription.havesubscription
                    "
                  >
                    <div class="col-sm-12 text-center">
                      <h6>High Rollers Slip of the day</h6>
                      <h6>
                        Slip Odd {{ highrollersbettingtips.totalOdd }}
                      </h6>
                    </div>

                    <div class="col-sm-12 text-center" v-if="user.loggedIn">
                      <a
                        href=""
                        @click.prevent="openPaymentModal('highroller')"
                        class="btn btn-success btn-sm"
                        style="font-size: 13px !important; border-radius: 28px"
                        ><i class=""></i> UNLOCK $5</a
                      >
                    </div>
                    <div class="col-sm-12 text-center" v-else>
                      <router-link
                        to="/Signin"
                        class="btn btn-success btn-sm"
                        style="font-size: 13px !important; border-radius: 28px"
                        ><i class=""></i> UNLOCK $5</router-link
                      >
                    </div>
                  </div>

                  <div v-else class="text-center">
                    <router-link
                      to="/Highrollers"
                      class="btn btn-secondary btn-sm"
                      style="font-size: 13px !important; border-radius: 28px"
                      ><i class="fas fa-gift mr-2"></i> Preview
                      High Rollers Slip</router-link
                    >
                  </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                 <div
                   class="card mb-3 featuredslip"
    
                 >
                   <div class="card-body" style="height: 150px!important">
                     <div class="row">

                       <div class="col-sm-12 text-center">
                        <h6>Get access to all</h6>
                        <h6>
                          VIP tips today.
                        </h6>
                      </div>
                       <div class="col-12 text-center">
                         <router-link
                           v-if="!subscription.havesubscription"
                           to="/premium-content"
                           class="btn btn-success btn-sm"
                           style="
                             font-size: 13px !important;
                             background: linear-gradient(45deg, gold, #fc742a);
                             border-radius: 28px;
                             color: black;
                           "
                           ><i class="fas fa-crown mr-1"></i> More on
                           VIP</router-link
                         >
                         <router-link
                           v-else
                           to="/Viptips"
                           class="btn btn-success btn-sm"
                           style="
                             font-size: 13px !important;
                             background: linear-gradient(45deg, gold, #fc742a);
                             border-radius: 28px;
                             color: black;
                           "
                           ><i class="fas fa-crown mr-1"></i> More on
                           VIP</router-link
                         >
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
          </div>



          <div class="jumbotron">
            <h3 style="font-weight: 700; border-bottom: solid 3px teal">
              Free sports betting tips and reviews by experts
            </h3>
            <p class="fontweightpara">
              All the latest FREE betting tips for today, tomorrow and the
              weekend posted by sports experts. Vip Betting Tips offers you a
              fantastic range of sports betting tips, odds comparison, and
              betting predictions for football, basketball, and other sports.
              Fully independent & unbiased.
            </p>

            <p class="fontweightpara">
              You will also find helpful articles and review for sports betting,
              available mobile applications, special bonuses and promotions and
              much more.
            </p>

            <div
              id="yesterslips"
              class="card"
              style="border-radius: 10px; background: #e5e5e5"
            >
              <div class="card-body text-center">
                <h5>Yesterday's Results</h5>
              </div>
            </div>
            <!-- Yesterdays football tips here -->
            <div class="row mb-4 my-4" v-if="loading">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-2 col-3">
                    <div class="roundround skeleton"></div>
                  </div>

                  <div class="col-sm-3 col-3 align-middle">
                    <h6
                      class="text-muted align-middle"
                      style="margin-top: 25px"
                    >
                      <div class="skeleton skeleton-text"></div>
                      <div class="skeleton skeleton-text"></div>
                    </h6>
                  </div>
                  <div class="col-sm-7 col-6">
                    <h6
                      style="margin-top: 15px; color: #3e1d9c; font-weight: 550"
                    >
                      <div class="skeleton skeleton-text"></div>
                      <div class="skeleton skeleton-text"></div>
                    </h6>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6">
                    <div class="skeleton skeleton-btn"></div>
                  </div>
                  <div class="col-6">
                    <div class="skeleton skeleton-btn"></div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <!-- end of loading condition -->

            <div id="horizontal-list" class="d-flex flex-row align-items-center overflow-auto my-4">
              <button class="btn btn btn-md m-1 text-nowrap" @click="selected_category = ''" :class="selected_category == '' ? 'btn-outline-dark': 'btn-dark'">
                 All
              </button>
              <button  v-for="category in yesterdaysCategories"  class="btn btn-sm btn-md btn-m-1 text-nowrap" :class="selected_category == category ? 'btn-outline-dark' : 'btn-dark'" @click="selected_category = category">
                  <img :src="returnImgFromTag(category)" style="height: 22px; margin-right: 10px;"/>
                  {{ categoryNameFromCode(category) }}
              </button>
              <!-- Add more chips as needed -->
            </div> 
            <div class="card scroll d-none d-lg-block my-4">
              <div class="card-body">

                
                <div
                  class="row"
                  id="forlargerscreens2"
                  v-for="tip in returnSelecteCategory"
                  :key="tip.key"
                >
                  <div class="col-12">
                    <div class="card my-2">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-2 text-center">
                            <img
                              style="position: relative; bottom: 30px"
                              :src="returnImgFromTag(tip.tag)"
                              class="bettingicons img-responsive mt-4"
                              alt="betway"
                            />
                            
                            <!-- </div> -->
                          </div>

                          <div class="col-3">
                            {{ tip.league }}
                          </div>

                          <div class="col-4">
                            <span class="mr-2" style="font-weight: 700">{{
                              tip.team_one_name
                            }}</span>
                            <img
                              src="../assets/images/japan.webp"
                              class="mr-2"
                              alt=""
                            />
                            <span class="mr-2" style="font-weight: 600">-</span>
                            <img
                              src="../assets/images/australia.webp"
                              alt=""
                              class="mr-2"
                            />
                            <span style="font-weight: 700">{{
                              tip.team_two_name
                            }}</span>
                          </div>

                          <div class="col-2 text-left">
                            <p style="font-weight: 400; font-size: 14px">
                              Tip:
                              <span style="color: #0679b0; font-weight: 800">{{
                                tip.prediction
                              }}</span>
                            </p>
                            <p
                              style="
                                font-weight: 400;
                                font-size: 14px;
                                margin-bottom: 0 !important;
                              "
                            >
                              Best Odds:
                              <span style="color: #1b9d11; font-weight: 800">
                                {{ tip.odd }}
                              </span>
                            </p>
                          </div>

                          <div class="col-1 my-3 text-center">
                            <span v-if="tip.outcome == 'correct' || tip.outcome == ' correct' || tip.outcome == 'correct ' || tip.outcome == ' correct '">
                              <i class="fas fa-check" style="color: green"></i>
                            </span>
                            <span v-else>
                              <i class="fas fa-xmark" style="color: red"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="alert alert-info text-center" v-if="returnSelecteCategory.length <= 0">
                  No Betting tips registered under this sports. 
                </div>
              </div>
            </div>
            <!-- yesterdays for small screens -->
            <div class="row my-3 d-lg-none" id="forsmallscreensyesterdaystips">
              <div class="col-12 mb-2">
                <section>

                  <!-- <div class="cards-wrapper" id="bazuwrapper" >
                          <div class="card" v-for="tip in yesterdaysbettingtips" :key="tip.key">
                            <div class="card-header" style="background:#082539; height:50px; border-bottom:solid 4px #E52017;">
                              <div class="row">
                                <div class="col-3">
                                  <img src="../assets/images/sa.webp" style="height:25px;" class="mr-2" alt="">
                                </div>
                                <div class="col-9 text-left text-nowrap">
                                  <span style="color:#fff; font-size:12px;">
                                     {{tip.league}}
                                  </span>
                                </div>

                              </div>

                            </div>

                            <div class="card-body">
                              <div class="row">
                                <div class="col-6 text-el">
                                  <span class="badge" style="background:#EBFAFF; color:#333!important;">{{tip.prediction}}</span>
                                </div>
                                <div class="col-6 text-right">
                                  <i class="fas fa-star mr-1" style="color:#E52017;"></i>
                                  <i class="fas fa-star mr-1" style="color:#E52017;"></i>
                                  <i class="fas fa-star mr-1" style="color:#E52017;"></i>
                                </div>
                              </div>

                              <div class="row my-4">
                                <div class="col-8 text-center">
                                  <h6 style="color:black; font-weight:600;"> {{tip.team_two_name}}</h6>
                                  <span style="position:relative; left:0;">Vs</span>
                                  <h6 style="color:black; font-weight:600;"> {{tip.team_one_name}}</h6>

                                </div>

                                <div class="col-4 text-center" >
                                  <span class="badge badge-success">odds</span>
                                  <span style="color:black;" class="text-muted my-3">
                                    {{tip.odd}}
                                  </span>

                                </div>
                              </div>


                            </div>

                          </div>



                      </div> -->
                  
                      <div class="cards-wrapper" id="bazuwrapper">
                    <div
                      class="card my-2"
                      v-for="tip in returnSelecteCategory"
                      :key="tip.key"
                    >
                      <div class="card-body">
                        <div class="row">
                          <div
                            class="col-12 text-center"
                            style="font-weight: 600"
                          >
                            <span class="" style="font-size: 15px">{{
                              tip.league
                            }}</span>
                          </div>
                          <div
                            class="col-12 text-center my-1"
                            style="font-weight: 400"
                          >
                            <span class="" style="font-size: 12px"
                              >Betting Tips</span
                            >
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <img
                              :src="returnImgFromTag(tip.tag)"
                              style="position: relative; bottom: 30px"
                              class="bettingicons img-responsive mt-3"
                              alt="betway"
                            />
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-6 text-center">
                            <!-- <img src="../assets/images/leicester_city_1@2x.webp" style="border-radius:1px; height:40px;" class="mr-2" alt="">   -->
                            <h6 style="margin-bottom: 0px; margin-top: 3px">
                              {{ tip.team_one_name }}
                            </h6>
                          </div>

                          
                          <div class="col-6 text-center">
                            <!-- <img src="../assets/images/Manchester_United_1@2x.webp" style="border-radius:1px; height:40px;" alt="" class="mr-2">                         -->
                            <h6 style="margin-bottom: 0px; margin-top: 3px">
                              {{ tip.team_two_name }}
                            </h6>
                          </div>
                          <!-- <div class="col-12 text-center">
                                  <span class="mr-2" style="font-weight:500;font-size:11px;">{{tip.team_one_name}}</span> <img src="../assets/images/japan.webp" class="mr-2" alt="">
                                  <span class="mr-2" style="font-weight:500;font-size:11px;">-</span>  <img src="../assets/images/australia.webp" alt="" class="mr-2"> <span style="font-weight:500;font-size:11px;">{{tip.team_two_name}}</span>
                              </div> -->
                        </div>

                        <div class="row">
                          <div class="col-12 text-center">
                            <span style="font-size: 11px">Prediction:</span>
                          </div>
                          <div class="col-12 text-center">
                            <span style="color: #0679b0; font-weight: 500">{{
                              tip.prediction
                            }}</span>
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <span style="font-size: 11px"> Best Odds:</span>
                          </div>
                          <div class="col-12 text-center">
                            <span style="color: #fc742a; font-weight: 500">{{
                              tip.odd
                            }}</span>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12 text-center">
                            <span style="font-size: 11px"> Outcome:</span>

                            <h6 v-if="tip.outcome == 'correct'">
                              <i class="fas fa-check" style="color: green"></i>
                            </h6>
                            <h6 v-else>
                              <i class="fas fa-xmark" style="color: red"></i>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>

                     <div style="width:100%" class="alert alert-info text-center" v-if="returnSelecteCategory.length <= 0">
                      No Betting tips registered under this sports. 
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <!-- Free tips here -->

            <div
              id="freeslips"
              class="card mb-2"
              style="border-radius: 10px; background: #082539; color: #fff"
            >
              <div class="card-body text-center">
                <h4>Free Betting Tips</h4>
              </div>
            </div>
            
            <div id="horizontal-list2" class="d-flex flex-row align-items-center overflow-auto my-4">
             <button class="btn btn-md m-1 text-nowrap" @click="selected_category_free_tips = ''" :class="selected_category_free_tips == '' ? 'btn-outline-dark' : 'btn-dark'">
               All
             </button>
             <button  v-for="category in freeBettingCaetegories"  class="btn btn-sm btn-m-1 text-nowrap" :class="selected_category_free_tips == category ? 'btn-outline-dark' : 'btn-dark'" @click="selected_category_free_tips = category">
                 <img :src="returnImgFromTag(category)" style="height: 22px; margin-right: 10px;"/>
                 {{ categoryNameFromCode(category) }}
             </button>
           <!-- Add more chips as needed -->
           </div> 
    

            <div class="row my-1 d-lg-none" id="forsmallscreens2">
              <div
                class="col-12 col-sm-6"
                v-for="tip in returnSelecteFreebettingtipsCategory"
                :key="tip.key"
              >
                <div class="card my-2">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 text-center" style="font-weight: 600">
                        <span class="" style="font-size: 15px">{{
                          tip.league
                        }}</span>
                      </div>
                      <div
                        class="col-12 text-center my-1"
                        style="font-weight: 400"
                      >
                        <span class="" style="font-size: 12px"
                          >Betting Tips</span
                        >
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-12 text-center">
                        <img
                          :src="returnImgFromTag(tip.tag)"
                          style="position: relative; bottom: 30px"
                          class="bettingicons img-responsive mt-4"
                          alt="betway"
                        />
                      
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-5 text-center">
                        <!-- <img src="../assets/images/leicester_city_1@2x.webp" style="border-radius:1px; height:40px;" class="mr-2" alt="">   -->
                        <h6 style="margin-bottom: 0px; margin-top: 3px">
                          {{ tip.team_one_name }}
                        </h6>
                      </div>
                      <div class="col-2 text-center text-info">
                        Vs
                      </div>
                      <div class="col-5 text-center">
                        <!-- <img src="../assets/images/Manchester_United_1@2x.webp" style="border-radius:1px; height:40px;" alt="" class="mr-2">                         -->
                        <h6 style="margin-bottom: 0px; margin-top: 3px">
                          {{ tip.team_two_name }}
                        </h6>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 text-center">
                        <span style="font-size: 13px">Prediction:</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 text-center">
                        <span style="color: #0679b0; font-weight: 500">{{
                          tip.prediction
                        }}</span>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-12 text-center">
                        <span style="font-size: 11px"> Best Odds:</span>
                      </div>
                      <div class="col-12 text-center">
                        <span style="color: #fc742a; font-weight: 500">{{
                          tip.odd
                        }}</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 text-center">
                        <div
                          class="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <a
                            href="https://www.betway.co.ke/?btag=P90167-PR22293-CM68364-TS1949656"
                            target="_blank"
                            class="btn btn-sm btn-primary"
                            style="background: #fff !important; padding: 3px"
                          >
                            <div class="row">
                              <div class="col-12 text-center">
                                <center>
                                  <img
                                    src="../assets/images/1xbet.webp"
                                    class=""
                                    style="height: 20px; margin-top: 4px"
                                    alt="Image"
                                  />
                                </center>
                              </div>
                            </div>
                          </a>
                          <a
                            href="https://www.betway.co.ke/?btag=P90167-PR22293-CM68364-TS1949656"
                            target="_blank"
                            class="btn btn-sm btn-primary"
                            style="background: #fc742a !important"
                            >Bet now!</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                 <div class="alert alert-info text-center" v-if="returnSelecteFreebettingtipsCategory.length <= 0">
                    No Betting tips registered under this sports. 
                  </div>
              </div>

            </div>
            <!-- for large screens -->
            <div class="d-none d-lg-block">
              <div class="row my-1">
                <div
                  class="col-6"
                  v-for="tip in returnSelecteFreebettingtipsCategory"
                  :key="tip.key"
                >
                  <div class="card my-2" style="">
                    <div class="card-body">
                      <div class="row">
                        <div
                          class="col-12 text-center"
                          style="font-weight: 600"
                        >
                          <span class="" style="font-size: 20px">{{
                            tip.league
                          }}</span>
                        </div>
                        <div
                          class="col-12 text-center my-1"
                          style="font-weight: 400"
                        >
                          <span class="" style="font-size: 14px"
                            >Betting Tips</span
                          >
                        </div>
                      </div>

                      <div class="row my-2">
                        <div class="col-12 text-center">
                          <img
                            :src="returnImgFromTag(tip.tag)"
                            style="position: relative; bottom: 30px"
                            class="bettingicons img-responsive mt-4"
                            alt="betway"
                          />
                       
                        </div>
                      </div>

                      <div class="row my-2">
                        <div class="col-5 text-center">
                          <!-- <img src="../assets/images/leicester_city_1@2x.webp" style="border-radius:1px; height:40px;" class="mr-2" alt="">   -->
                          <h6
                            style="
                              margin-bottom: 0px;
                              margin-top: 3px;
                              font-weight: 500;
                            "
                          >
                            {{ tip.team_one_name }}
                          </h6>
                        </div>

                        <div class="col-2 text-center text-info">
                        Vs
                      </div>
                        <div class="col-5 text-center">
                          <!-- <img src="../assets/images/Manchester_United_1@2x.webp" style="border-radius:1px; height:40px;" alt="" class="mr-2">                         -->
                          <h6
                            style="
                              margin-bottom: 0px;
                              margin-top: 3px;
                              font-weight: 500;
                            "
                          >
                            {{ tip.team_two_name }}
                          </h6>
                        </div>
                        <!-- <div class="col-12 text-center">
                            <span class="mr-2" style="font-weight:500;font-size:11px;">{{tip.team_one_name}}</span> <img src="../assets/images/japan.webp" class="mr-2" alt="">
                            <span class="mr-2" style="font-weight:500;font-size:11px;">-</span>  <img src="../assets/images/australia.webp" alt="" class="mr-2"> <span style="font-weight:500;font-size:11px;">{{tip.team_two_name}}</span>
                        </div> -->
                      </div>

                      <div class="row my-4">
                        <div class="col-12 text-center">
                          <span style="font-size: 14px">Prediction:</span>
                        </div>
                        <div class="col-12 text-center">
                          <span style="color: #0679b0; font-weight: 500">{{
                            tip.prediction
                          }}</span>
                        </div>
                      </div>

                      <div class="row my-2">
                        <div class="col-12 text-center">
                          <span style="font-size: 11px"> Best Odds:</span>
                        </div>
                        <div class="col-12 text-center">
                          <span style="color: #fc742a; font-weight: 500">{{
                            tip.odd
                          }}</span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 text-center">
                          <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic example"
                          >
                            <a
                              href="https://www.betway.co.ke/?btag=P90167-PR22293-CM68364-TS1949656"
                              target="_blank"
                              class="btn btn-primary"
                              style="
                                background: #fff !important;
                                padding: 3px;
                                border: solid black 1px;
                                border-top-left-radius: 6px;
                                border-bottom-left-radius: 6px;
                              "
                            >
                              <div class="row">
                                <div class="col-12 text-center">
                                  <center>
                                    <img
                                      src="../assets/images/1xbet.webp"
                                      class=""
                                      style="height: 20px; margin-top: 10px"
                                      alt="Image"
                                    />
                                  </center>
                                </div>
                              </div>
                            </a>
                            <a
                              href="https://www.betway.co.ke/?btag=P90167-PR22293-CM68364-TS1949656"
                              target="_blank"
                              class="btn btn-primary"
                              style="
                                background: #fc742a !important;
                                border-top-right-radius: 6px;
                                border-bottom-right-radius: 6px;
                              "
                              >Bet now!</a
                            >
                          </div>
                        </div>

                        <div class="col-12 my-3 text-center">
                          <small style="color: #808080; font-weight: 600"
                            >up.to</small
                          >
                          <span style="color: #fc742a; font-weight: 600">
                            10000KES Bonus
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                   <div class="alert alert-info text-center" v-if="returnSelecteFreebettingtipsCategory.length <= 0">
                      No Betting tips registered under this sports. 
                    </div>
                </div>
              </div>
            </div>

            <div class="row my-2">
              <div class="col-12 text-center">
                <router-link
                  v-if="!subscription.havesubscription"
                  to="/premium-content"
                  class="btn btn-success"
                  style="
                    border-radius: 20px;
                    background: linear-gradient(45deg, gold, #fc742a);
                    border-radius: 28px;
                    color: black;
                  "
                  ><i class="fas fa-crown mr-1"></i> More On VIP Section
                </router-link>
                <router-link
                  v-else
                  to="/Viptips"
                  class="btn btn-success"
                  style="
                    border-radius: 20px;
                    background: linear-gradient(45deg, gold, #fc742a);
                    border-radius: 28px;
                    color: black;
                  "
                  ><i class="fas fa-crown mr-1"></i> More On VIP Section
                </router-link>
              </div>
            </div>

            <h3
              class="my-2"
              style="border-bottom: solid 3px #0679b0; font-weight: 700"
            >
              Why you should trust us
            </h3>

            <p style="font-weight: 400" class="my-2">
              We are experts in sports and sports betting, people who have
              dedicated their lives to this area of expertise. Follow
              Predictions, use our knowledge and experience, and you will
              increase your chances of winning.
            </p>

            <p style="font-weight: 400">
              All of our reviews and tests are carried out independently and
              objectively. We are not tied to any bookmaker. Our tips are 100%
              honest and are regularly updated to stay relevant the moment you
              check them out.
            </p>

            <p style="font-weight: 400">
              For more information about the Vip Betting Tips team, please see
              our About Us page.
            </p>

            <div class="row">
              <div class="col-lg-4 col-sm-6 col-12 mb-3">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 text-center">
                        <img src="../assets/images/search.webp" alt="" />

                        <h1 class="my-2">200+</h1>

                        <p>bookmaker reviews</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6 col-12 mb-3">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 text-center">
                        <img src="../assets/images/gift.svg" alt="" />

                        <h1 class="my-2">350+</h1>

                        <p>sports tips monthly</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-12 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 text-center">
                        <img src="../assets/images/book.webp" alt="" />

                        <h1 class="my-2">30,000+</h1>

                        <p>visitors a day</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DownloadApp />



            <!-- payment dialog -->

          
    </div>
        </div>
        <Offers />
      </div>
    </div>

    <!-- footer goes in hear -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-md " role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title text-center" id="exampleModalLabel">
              <h3 style="font-weight: 800;">
                Choose a CheckOut Method
              </h3>
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="card" @click="selectRadio('mobilemoney')" style="cursor: pointer;">
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <img src="https://cdn-icons-png.flaticon.com/512/6811/6811723.png" class="img-responsive mr-3" style="height: 70px;" />
                        Mobile Money
                      </div>

                      <div>
                        
                        <div v-if="selectedOption == 'mobilemoney'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mt-4" @click="selectRadio('stripe')" style="cursor: pointer;">
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <img src="../assets/images/stripe.webp" class="img-responsive" style="height: 50px;" />

                        Stripe
                      </div>

                      <div>
                    

                        <div v-if="selectedOption == 'stripe'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>

                      </div>
                    </div>
                  </div>
                </div>


             
                <button class="btn btn-info btn-block my-4" @click="proceedToCheckout">Proceed <i
                    class="fas fa-arrow-right ml-1"></i></button>


              </div>
            </div>
          </div>
        </div>
      </div>
  </div>


    <div class="modal fade" id="countryModal" tabindex="-1" role="dialog" aria-labelledby="countryModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-md " role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title text-center" id="countryModalLabel">
              <h3 style="font-weight: 800;">
                Choose a Country
              </h3>              
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="height: 500px; overflow-y: auto; overflow-x: hidden;">
            <div > 
              <div class="row">
                <div class="col-12">
                  <div class="card" @click="selectCountry('kenya')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="https://cdn-icons-png.flaticon.com/512/3955/3955604.png" class="img-responsive" style="height: 70px;" />
                          Kenya
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'kenya'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
  
                  <div class="card mt-4" @click="selectCountry('Nigeria')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="   https://cdn-icons-png.flaticon.com/512/5327/5327538.png " class="img-responsive mr-4" style="height: 50px;" />
                          Nigeria
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'Nigeria'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="card mt-3" @click="selectCountry('Ghana')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="https://cdn-icons-png.flaticon.com/512/555/555424.png " class="img-responsive mr-4" style="height: 50px;" />
                          Ghana
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'Ghana'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="card mt-3" @click="selectCountry('Uganda')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="   https://cdn-icons-png.flaticon.com/512/555/555670.png " class="img-responsive mr-4" style="height: 50px;" />
                          Uganda
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'Uganda'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="card mt-3" @click="selectCountry('Rwanda')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="   https://cdn-icons-png.flaticon.com/512/555/555436.png " class="img-responsive mr-4" style="height: 50px;" />
                          Rwanda
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'Rwanda'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="card mt-3" @click="selectCountry('Zambia')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="   https://cdn-icons-png.flaticon.com/512/555/555663.png " class="img-responsive mr-4" style="height: 50px;" />
                          Zambia
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'Zambia'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="card mt-3" @click="selectCountry('Tanzania')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="   https://cdn-icons-png.flaticon.com/512/206/206846.png " class="img-responsive mr-4" style="height: 50px;" />
                          Tanzania
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'Tanzania'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>

         

                  <div class="card mt-3" @click="selectCountry('XAF')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <img src="   https://cdn-icons-png.flaticon.com/512/3492/3492623.png " class="img-responsive mr-4" style="height: 50px;" />
                        <div class="d-flex flex-column">
                          <p><b>Francophone mobile money(XAF) </b></p>
                          Cameroon, Central African Republic, Chad, Republic of the Congo, Equatorial Guinea and Gabon
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'XAF'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mt-3" @click="selectCountry('XOF')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <img src="   https://cdn-icons-png.flaticon.com/512/1959/1959169.png " class="img-responsive mr-4" style="height: 50px;" />
                        <div class="d-flex flex-column">
                          <p><b>Francophone mobile money (XOF) </b></p>
                          Benin, Burkina Faso, Côte d'Ivoire, Guinea-Bissau, Mali, Niger, Senegal and Togo
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'XOF'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="card mt-3" @click="selectCountry('Other')" style="cursor: pointer;">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img src="    https://cdn-icons-png.flaticon.com/512/9985/9985721.png  " class="img-responsive mr-4" style="height: 50px;" />
                          Other
                        </div>
  
                        <div>
                          <div v-if="selectedCountry == 'Other'" style="width:20px; height: 20px; border-radius: 50%; background: #33B5E5; border: solid 1px white;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  
  
  
                </div>            
              </div>
            </div>

            <button class="btn btn-primary btn-block my-4" @click="proceedToCheckoutMobileMoney">Proceed <i
                    class="fas fa-arrow-right ml-1"></i></button>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../db";
import {
  getDatabase,
  ref,
  set,
  query,
  onValue,
  orderByChild,
  equalTo,
  limitToLast,
  endBefore,
  endAt,
  startAfter,
} from "firebase/database";
import VueRssFeedDuplicate from "./VueRssFeedDuplicate.vue";
import Yesterday from "../components/YesterTips.vue";
import Offers from "../components/Offers.vue";
import { mapGetters, mapActions } from "vuex";
import VueInstagram from "../components/vue-instagram";
import DownloadApp from "../components/DownloadApp.vue";
import axiosInst from "../services/api";
import { useToast } from "vue-toastification";
import {useFlutterwave} from "flutterwave-vue3"

import store from "../store";

import axios from "axios";


const PIXALS_FROM_BOTTOM = 200;

export default {
  name: "Home",
  components: {
    VueRssFeedDuplicate,
    Yesterday,
    Offers,
    DownloadApp,
  },
  props: {
    feedUrl: String,
    name: String,
    limit: Number,
  },
  data() {
    return {
      documents: [],
      tel: '',
      publishableKey: 'pk_live_51N0kEvLz2kuZb2bS42azXJD4hJ9n07Dc55NbJkTOmiWrAmTmgvH6qI4uAKlBBlAlMxxUPRJ7jAAHj3F3TS2SPUGv00sOc30tsk',
      freebettingtips: [],
      featurebettingtips: [],
      highrollersbettingtips: [],
      yesterdaysbettingtips: [],
      currencyToUse: 'USD',
      show_loading:false,
      selectedOption: 'mobilemoney',
      selectedCountry: 'kenya',
      is_processing_payment: false,
      saved_tarrif: 'daily',
      transaction_failed: false,
      yesterdaysCategories: [],
      successURL1: 'https://thevipbettingtips.com/#/stripe-1?tarrif=daily',
      successURL2: 'https://thevipbettingtips.com/#/stripe-1?tarrif=highroller',
      lineItemsDaily: [
        {
          price: 'price_1NOC04Lz2kuZb2bSuwAUf3Yh', // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      stripe: null,
      freeBettingCaetegories: [],
      sportsCategories: [
        { name: 'Football', code: 'fb' },
        { name: 'Basketball', code: 'bb' },
        { name: 'Tennis', code: 'tn' },
        { name: 'Horse Racing', code: 'hr' },
        { name: 'Snooker', code: 'sn' },
        { name: 'Rugby', code: 'ru' },
        { name: 'American Football', code: 'af' },
        { name: 'Cricket', code: 'cr' },
        { name: 'Athletics', code: 'at' },
        { name: 'Boxing', code: 'bx' },
        { name: 'Handball', code: 'hb' },
        { name: 'Ice Hockey', code: 'ih' },
        { name: 'Volleyball', code: 'vb' },
        { name: 'Baseball', code: 'ba' },
        { name: 'Aussie Rules', code: 'ar' },
        { name: 'Cycling', code: 'cy' },
        { name: 'E Sports', code: 'es' },
        { name: 'Table Tennis', code: 'tt' },
        { name: 'Mixed Martial Arts', code: 'mma' },
        { name: 'Darts', code: 'da' },
        { name: 'Water Polo', code: 'wp' }
      ],
      selected_category: '',
      selected_category_free_tips: '',
      status_msg:
        "A Pop up has been sent to your Phone. Enter your PIN and do not leave this page, this may take a few seconds..",
      duration: "",
      saved_oid: "",
      // hasactivedailysubscription:false,
      loading: true,
      feedUrl: "https://www.sportskeeda.com/feed",
      feedUrl2:
        "https://api.foxsports.com/v1/rss?partnerKey=zBaFxRyGKCfxBagJG9b8pqLyndmvo7UU&tag=nba",
      name: "",
      limit: 20,

      paymentData: {
        //  public_key: "FLWPUBK_TEST-0b*****b4-X", //use if default is not set
        tx_ref: this.generateReference(),
        // payment_plan: 67878,
        amount: 130,
        currency: "KES",
        payment_options: "mpesa,card,ussd",
        redirect_url: "",
        meta: {
          counsumer_id: "7898",
          consumer_mac: "kjs9s8ss7dd",
        },
        customer: {
          name: "Demo Customer  Name",
          email: "customer@mail.com",
          phone_number: "08184500044",
        },
        customizations: {
          title: "Customization Title",
          description: "Customization Description",
          // logo: 'https://flutterwave.com/images/logo-colored.svg'
        },
        callback: this.makePaymentCallback,
        onclose: this.closedPaymentModal,
      },
    };
  },
  mounted() {

    this.stripe = Stripe(this.publishableKey)


    const horizontalList = document.getElementById('horizontal-list');
    const horizontalList2 = document.getElementById('horizontal-list2');

    console.log(horizontalList2);

    horizontalList.addEventListener('wheel', (event) => {
      event.preventDefault();
      const scrollAmount = event.deltaY;
      horizontalList.scrollLeft += scrollAmount;
    });

    horizontalList2.addEventListener('wheel', (event) => {
      event.preventDefault();
      const scrollAmount = event.deltaY;
      horizontalList2.scrollLeft += scrollAmount;
    });

    this.scrollEvent = window.addEventListener("scroll", () => {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - PIXALS_FROM_BOTTOM
      ) {
        this.increaseLimit();
      }
    });

    const starCountRef = ref(db, "betting_sites");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      let messages = [];
      Object.keys(data).forEach((key) => {
        messages.push({
          id: key,
          betname: data[key].name,
          description: data[key].description,
          link: data[key].url,
          icon: data[key].icon,
        });
      });

      this.documents = messages;
      this.loading = false;
      store.dispatch("setvisibility", true);
      console.log(this.documents);
    });

    const starCountRef2 = ref(db, "free_betting_predictions");
    onValue(starCountRef2, (snapshot) => {
      const data = snapshot.val();
      let messages2 = [];
      this.freeBettingCaetegories = []
      Object.keys(data).forEach((key) => {
        messages2.push({
          id: key,
          league: data[key].league,
          odd: data[key].odd,
          outcome: data[key].outcome,
          prediction: data[key].prediction,
          tag: data[key].tag,
          team_one_name: data[key].team_one_name,
          team_two_name: data[key].team_two_name,
        });
        if (!this.freeBettingCaetegories.includes(data[key].tag)) {
          this.freeBettingCaetegories.push(data[key].tag)
        }
      });

      this.freebettingtips = messages2;

      console.log(this.freebettingtips);
    });

    const starCountRef3 = ref(db, "yesterday_results_slips");
    onValue(starCountRef3, (snapshot) => {
      const data = snapshot.val();
      let messages3 = [];
      this.yesterdaysCategories = []
      Object.keys(data).forEach((key) => {
        messages3.push({
          id: key,
          league: data[key].league,
          odd: data[key].odd,
          outcome: data[key].outcome,
          prediction: data[key].prediction,
          tag: data[key].tag,
          team_one_name: data[key].team_one_name,
          team_two_name: data[key].team_two_name,
        });

        if(!this.yesterdaysCategories.includes(data[key].tag)){
          this.yesterdaysCategories.push(data[key].tag)
        }

      });

      this.yesterdaysbettingtips = messages3;

      console.log(this.freebettingtips);
    });


    const starCountRef4 = ref(db, "featured_betting_predictions");
    onValue(starCountRef4, (snapshot) => {
      const data = snapshot.val();
      let messages4 = [];
      Object.keys(data).forEach((key) => {
        messages4.push({
          id: key,
          league: data[key].league,
          slip_one_odd: data[key].slip_one_odd,
          slip_one_outcome: data[key].slip_one_outcome,
          slip_one_prediction: data[key].slip_one_prediction,
          slip_one_tag: data[key].slip_one_tag,
          slip_three_league: data[key].slip_three_league,
          slip_three_outcome: data[key].slip_three_outcome,
          slip_three_team_one_name: data[key].slip_three_team_one_name,
          slip_three_team_two_name: data[key].slip_three_team_two_name,
          slip_threeodd: data[key].slip_threeodd,
          slip_threeprediction: data[key].slip_threeprediction,
          slip_threetag: data[key].slip_threetag,
          slip_two_league: data[key].slip_two_league,
          slip_two_outcome: data[key].slip_two_outcome,
          slip_two_team_one_name: data[key].slip_two_team_one_name,
          slip_two_team_two_name: data[key].slip_two_team_two_name,
          slip_twoodd: data[key].slip_twoodd,
          slip_twoprediction: data[key].slip_twoprediction,
          slip_twotag: data[key].slip_twotag,
          team_one_name: data[key].team_one_name,
          team_two_name: data[key].team_two_name,
          total_odd: (
            data[key].slip_one_odd *
            data[key].slip_threeodd *
            data[key].slip_twoodd
          ).toFixed(2),
        });
      });

      this.featurebettingtips = messages4;

      console.log(this.featurebettingtips);
    });

    store.dispatch("fetchdailysubscription");


    const starCountRef5 = ref(db, "highroller_betting_predictions");
    onValue(starCountRef5, (snapshot) => {
      const data = snapshot.val();
      const highrollerSlip = data.highroller_slip;

      let totalOdd = 1;

      let slips = [];
      const slipKeys = ['one', 'two', 'three', 'four', 'five', 'six']


      slipKeys.forEach(key => {
        const slip = {
          league: highrollerSlip[`slip_${key}_league`],
          outcome: highrollerSlip[`slip_${key}_outcome`],
          teamOneName: highrollerSlip[`slip_${key}_team_one_name`],
          teamTwoName: highrollerSlip[`slip_${key}_team_two_name`],
          odd: highrollerSlip[`slip_${key}odd`],
          prediction: highrollerSlip[`slip_${key}prediction`],
          tag: highrollerSlip[`slip_${key}tag`]
        };

        // Only add slip if it has a valid tag
        if (slip.tag) {
          slips.push(slip);
          totalOdd *= slip.odd; 
        }
      });

      totalOdd = totalOdd.toFixed(2);


      this.highrollersbettingtips = { slips: slips, totalOdd: totalOdd };

      console.log('we are here bazu')
      console.log(this.highrollersbettingtips);
    });

    store.dispatch("fetchhighrollerslipssubscriptions");
  },
  
  destroyed() {
    if (this.scrollEvent) {
      window.removeEventListener(this.scrollEvent);
    }
  },

  methods: {
    launchFlutterWave(){
      useFlutterwave({
        amount: this.amountForFlutterwave,//amount
        callback() {
          //  TODO: handle callbacks
        },
        currency:  this.currencyToUse,
        customer: {email: localStorage.getItem("email"), name: '', phone_number: ''},
        customizations: {description: "Pay Now", logo: "", title: "VipBettingTips"},
        meta: {
          user_id: localStorage.getItem("uid"),
          tarrif: this.saved_tarrif
        },
        onclose() {

        },
        payment_options:  "card,mpesa,ussd,banktransfer,mobilemoneyghana,nqr,mobilemoneyuganda,mobilemoneyrwanda,mobilemoneyzambia,barter,credit,account,mobilemoneyfranco,mobilemoneytanzania",
        public_key: "FLWPUBK-c6e9f068abe9b2f556e9b5071210563e-X",
        redirect_url: "https://thevipbettingtips.com//#/flutterwave",
        tx_ref: this.generateReference()
      })
    },
     proceedToCheckout() {
      this.show_loading = true

      $("#exampleModal").modal("hide");
      if (this.selectedOption == 'mobilemoney') {
        this.show_loading = false
        $("#countryModal").modal("show");
      } 
      
      else {

        localStorage.setItem("PaymentLegit", true);

        //  console.log(this.successURL1);
        const toast = useToast();
        toast.success('You will be redirected to checkout page in a moment ...', {timeout: 5000})
          this.stripe.redirectToCheckout({
            successUrl: this.saved_tarrif == 'daily' ? this.successURL1 : this.successURL2,
            cancelUrl: 'https://thevipbettingtips.com/#/Subscribe',
            lineItems: this.lineItemsDaily,
            mode: "payment"
          })       
      }

    },
    async triggerMoneyCompute(){

      if(this.saved_tarrif == 'highroller'){
        await this.convertCurrency(5, 'USD', this.currencyToUse)
      }else{
        await this.convertCurrency(1, 'USD', this.currencyToUse)
      }    

  },

    async proceedToCheckoutMobileMoney(){
      if(this.selectedCountry == 'kenya'){
        this.currencyToUse = 'KES'
      }else if (this.selectedCountry == 'Nigeria'){
        this.currencyToUse = 'NGN'
      }else if (this.selectedCountry == 'Ghana'){
        this.currencyToUse = 'GHS'
      }else if (this.selectedCountry == 'Uganda'){
        this.currencyToUse = 'UGX'
      }else if (this.selectedCountry == 'Rwanda'){
        this.currencyToUse = 'RWF'
      }else if (this.selectedCountry == 'Zambia'){
        this.currencyToUse = 'ZMW'
      }else if (this.selectedCountry == 'Tanzania'){
        this.currencyToUse = 'TZS'
      }else if (this.selectedCountry == 'Other'){
        this.currencyToUse = 'USD'
      }else if (this.selectedCountry == 'XAF'){
        this.currencyToUse = 'XAF'
      }else if (this.selectedCountry == 'XOF'){
        this.currencyToUse = 'XOF'
      }

      $("#countryModal").modal("hide");

      await this.triggerMoneyCompute()

      localStorage.setItem("saved_tarrif", this.saved_tarrif)

      setTimeout(() => {

        console.log(this.amountForFlutterwave)

        // this.amountForFlutterwave = 1
        
        this.launchFlutterWave()
      }, 500); 

    },

    async convertCurrency(amount, fromCurrency, toCurrency, cb) {
      var apiKey = "50507e1e79cbb52820dc";

      fromCurrency = encodeURIComponent(fromCurrency);
      toCurrency = encodeURIComponent(toCurrency);
      var query = fromCurrency + "_" + toCurrency;

      var url =
        "https://free.currconv.com/api/v7/convert?q=" +
        query +
        "&compact=ultra&apiKey=" +
        apiKey;
      await axios
        .get(url)
        .then((response) => {
          console.log(response)
          let { data } = response;
          let tray = data[`USD_${toCurrency}`];

          console.log(tray * amount)

          this.amountForFlutterwave = tray * amount;
        })
        .catch((error) => {
          throw error;
        });
    },
    selectRadio(value) {
      this.selectedOption = value;
    },

    selectCountry(value) {
      this.selectedCountry = value;
    },

    openPaymentModal(duration) {
      $("#exampleModal").modal("show");
      this.saved_tarrif = duration

      console.log(this.saved_tarrif);
      // this.duration = duration;
    },
    categoryNameFromCode(code){
      return this.sportsCategories.find(x => x.code == code).name
    },
    returnImgFromTag(tag){

      if(tag == 'fb'){
        return require("@/assets/images/footballimage.webp");
      }else if(tag == 'bb'){
        return require("@/assets/images/basketball.webp");
      }else if(tag == 'tn'){
        return require("@/assets/images/tennis.webp");
      }else if(tag == 'hr'){
        return require("@/assets/images/horserace.webp");
      }else if(tag == 'ru'){
        return require("@/assets/images/rugby.webp");
      }else if(tag == 'af'){
        return require("@/assets/images/americanfb.webp");
      }else if(tag == 'at'){
        return require("@/assets/images/athlete.webp");
      }
      else if(tag == 'bx'){
        return require("@/assets/images/boxing.webp");
      }
      else if(tag == 'hb'){
        return require("@/assets/images/handball.webp");
      }
      else if(tag == 'ih'){
        return require("@/assets/images/icehokey.webp");
      }
      else if(tag == 'vb'){
        return require("@/assets/images/voleyball.webp");
      }
      else if(tag == 'sn'){
        return require("@/assets/images/snooker.webp");
      }
      else if(tag == 'cr'){
        return require("@/assets/images/cricket.webp");
      }
      else if(tag == 'ba'){
        return require("@/assets/images/baseball.webp");
      }
      else if(tag == 'ar'){
        return require("@/assets/images/assie-rule.webp");
      }
      else if(tag == 'cy'){
        return require("@/assets/images/cycling.webp");
      }
      else if(tag == 'es'){
        return require("@/assets/images/boxing.webp");
      }
      else if(tag == 'tt'){
        return require("@/assets/images/tt.webp");
      }
      else if(tag == 'mma'){
        return require("@/assets/images/martialarts.webp");
      }
      else if(tag == 'da'){
        return require("@/assets/images/dart.webp");
      }
      else if(tag == 'wp'){
        return require("@/assets/images/waterpollo.webp");
      }

    },
    increaseLimit(loadMore = 5) {
      this.limit += loadMore;
    },
    loadExample(evt) {
      evt.preventDefault();
      this.rssFeedForm.feedUrl = evt.toElement.href;
      this.feedUrl = evt.toElement.href;
    },
    payViaService() {
      this.payWithFlutterwave(this.paymentData);
    },
    async makePaymentCallback(response) {
      console.log("Pay", response);

      if (response.status == "successful") {
        console.log("we are saving this to db");
        const userid = localStorage.getItem("uid");
        const transaction_id = response.transaction_id;
        const flw_ref = response.flw_ref;
        const todaysTimeStamp = new Date().getTime();
        const one_week_later2 = new Date(Date.now() + 1 * 24 * 60 * 60 * 1000);
        const one_week_later = one_week_later2.getTime();

        await set(ref(db, "dailysubscriptions/" + transaction_id), {
          userid: userid,
          transaction_id: transaction_id,
          flw_ref: flw_ref,
          subscription_plan: "daily",
          start_date: todaysTimeStamp,
          end_date: one_week_later,
        });
        // writeUserData(response)
        console.log("iko sawa");
        this.$router.replace({
          name: "Home",
        });
      }
    },
    closedPaymentModal() {
      console.log("payment is closed");
    },

    generateReference() {
      let date = new Date();
      return date.getTime().toString();
    },

    // openPaymentModal(duration) {
    //   $("#exampleModal").modal("show");
    //   this.duration = duration;
    // },

     async payMpesa(method) {
      console.log(this.duration);
      if (this.tel == null || this.tel == "") {
        const toast = useToast();
        toast.error(
          "You must enter a phone number.",
          {
            timeout: 3000,
          });
        return false;
      }

      let formData;
       formData = {
          amount: "120",
          tel: this.tel,
        };



      this.status_msg =
        "A Pop up has been sent to your Phone. Enter your PIN and do not leave this page, this may take a few seconds..";

      this.is_processing_payment = true;
      this.transaction_failed = false;
      $("#mpesa_btn").addClass("disabled");
      $("#mpesa_btn_spinner").addClass("spinner-border ");
      let url
      if(method == 'mpesa'){
        url = '/payment/stk_mpesa'
      }else if(method == 'airtel'){
        url = '/payment/stk_airtell'
      }
      axiosInst
        .post(`${url}`, formData)
        .then((response) => {
          let { data } = response;
          console.log(data);
          this.saved_oid = data.oid;

          setTimeout(() => {
            const searchData = {
              oid: data.oid,
            };
            this.status_msg =
              "searching for your transaction in our database...";
            axiosInst
              .post(`/payment/search`, searchData)
              .then((response) => {
                let { data } = response;
                console.log(data);

                if (data.header_status == 404) {
                  this.is_processing_payment = false;
                  $("#mpesa_btn").removeClass("disabled");
                  $("#mpesa_btn_spinner").removeClass("spinner-border ");
                  this.transaction_failed = true;
                } else if (data.header_status == 200) {
                  this.is_processing_payment = false;
                  $("#mpesa_btn").removeClass("disabled");
                  $("#mpesa_btn_spinner").removeClass("spinner-border ");

                  console.log(data);

                  const userid = localStorage.getItem("uid");
                  const transaction_id = data.data.transaction_code;
                  const flw_ref = data.data.oid;
                  const todaysTimeStamp = new Date().getTime();


                  let time_lapse = new Date(Date.now() + 1 * 24 * 60 * 60 * 1000);

                  console.log(time_lapse);

                  const final_lapse = time_lapse.getTime();

                  set(ref(db, "dailysubscriptions/" + transaction_id), {
                    userid: userid,
                    transaction_id: transaction_id,
                    flw_ref: flw_ref,
                    subscription_plan: "daily",
                    start_date: todaysTimeStamp,
                    end_date: final_lapse,
                  }).then(() => {
                    console.log("iko sawa");
                    $("#exampleModal").modal("hide");
                    this.$router
                      .replace({
                        name: "Home",
                      })
                      .then(() => {
                        const toast = useToast();
                        toast.success(
                          "You have successfully subscribed to our VIP Package.",
                          {
                            timeout: 6000,
                          }
                        );
                      });
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }, 30000);
        })
        .catch((error) => {
          console.log(error);
        });
    },

     retrySearch() {
      const searchData = {
        oid: this.saved_oid,
      };
      this.is_processing_payment = true;
      $("#tryagainbtn").addClass("disabled");
      $("#try_again_spinner").addClass("spinner-border");
      axiosInst
        .post(`/payment/search`, searchData)
        .then((response) => {
          let { data } = response;
          console.log(data);

          if (data.header_status == 404) {
            this.is_processing_payment = false;
            $("#tryagainbtn").removeClass("disabled");
            $("#try_again_spinner").removeClass("spinner-border");
            this.transaction_failed = true;
          } else if (data.header_status == 200) {
            this.is_processing_payment = false;
            this.transaction_failed = false;
            $("#tryagainbtn").removeClass("disabled");
            $("#try_again_spinner").removeClass("spinner-border");

            console.log(data);

            const userid = localStorage.getItem("uid");
            const transaction_id = data.data.transaction_code;
            const flw_ref = data.data.oid;
            const todaysTimeStamp = new Date().getTime();
            let time_lapse;

            if (this.duration == "weekly") {
              time_lapse = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
            } else if (this.duration == "monthly") {
              time_lapse = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
            } else if (this.duration == "annually") {
              time_lapse = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
            }

            console.log(time_lapse);

            const final_lapse = time_lapse.getTime();

            set(ref(db, "subscriptions/" + transaction_id), {
              userid: userid,
              transaction_id: transaction_id,
              flw_ref: flw_ref,
              subscription_plan: "monthly",
              start_date: todaysTimeStamp,
              end_date: final_lapse,
            }).then(() => {
              console.log("iko sawa");
              $("#exampleModal").modal("hide");
              this.$router
                .replace({
                  name: "Home",
                })
                .then(() => {
                  const toast = useToast();
                  toast.success(
                    "You have successfully subscribed to VIP Package.",
                    {
                      timeout: 3000,
                    }
                  );
                });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    getFeedUrl() {
      return this.feedUrl;
    },
  },

  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      subscription: "subscription",
      dailysubscription: "mydailysubscription",
      highrollerslipssubscription: "myhighrollerslips",
    }),

    returnSelecteCategory(){
      if(this.selected_category != ''){
        return this.yesterdaysbettingtips.filter(x => x.tag == this.selected_category)
      }else{
        return this.yesterdaysbettingtips
      }
    },
    returnSelecteFreebettingtipsCategory(){
      if(this.selected_category_free_tips != ''){
        return this.freebettingtips.filter(x => x.tag == this.selected_category_free_tips)
      }else{
        return this.freebettingtips
      }
    }
  },
};
</script>

<style scoped>
@media screen and (min-width: 600px) {
  .mine {
    margin-top: 60px;
  }

  .icon-bubbles {
    font-size: 40px;
  }
}

section {
  display: grid;
  grid-template-columns: var(--page-margin) [center-start] 1fr [center-end] var(
      --page-margin
    );
}

section > * {
  grid-column: center;
}

.cards-wrapper {
  grid-column: center-start / -1;
  overflow: auto;
  display: flex;
  padding-bottom: 1.9em;
}

/* .cards-wrapper::-webkit-scrollbar {
       width: 100px;
    } */

.cards-wrapper .card {
  /* background-color: #ced3d3; */
  color: #333;
  height: 390px;
  min-width: 250px;
  margin-right: 5px;
  margin-left: 3px;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
}

::-webkit-scrollbar {
  width: 7px;
  border-radius: 20px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(192, 188, 188);
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* ::-webkit-scrollbar-thumb:horizontal{
        height: 3px;
    } */
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cards-wrapper .card p {
  background-color: rgba(124, 68, 4, 0.932);
  margin-top: 115px;
  font-size: 14px;
  color: white;
  width: 60px;
  height: 20px;
  text-align: center;
  border-radius: 10px;
}

.cards-wrapper .card p:last-child {
  margin-right: 0;
}

.cards-wrapper .card p::after {
  content: "";
  display: block;
  min-width: var(--page-margin);
}

.item {
  position: relative;
}

.slip {
  background: linear-gradient(
                    90deg,
                    rgba(47, 20, 2, 0.94),
                    rgb(182, 124, 1)
                  )!important;
                  color: #fff;
}
.notify-badge {
  position: absolute;
  right: 35%;
  top: 10%;
  background: green;
  height: 25px;
  width: 20px;
  border-radius: 50%;
  border: solid 3px white;
  color: white;
  padding: 5px 10px;
}

.remove-default-btn:focus {
  outline: none;
}

/* the non scroll section styles here */

.fontweightpara {
  font-weight: 400;
}

.flagkenya {
  height: 30px;
  width: 30px;
}

.jumbotronMain {
  border-left: solid 2px teal;
  border-right: solid 2px teal;
}

#topbookmarkerbold {
  font-weight: 500;
}

.scroll {
  max-height: 500px;
  overflow-y: auto;
}

@media screen and (max-width: 1199.98px) {
  .ballhand {
    position: relative;
    left: -30px;
  }
}

@media screen and (max-width: 991.98px) {
  .ballhand {
    position: relative;
    left: 100px;
  }
}

@media screen and (max-width: 769px) {
  .ballhand {
    position: relative;
    left: 70px;
  }
}

@media screen and (max-width: 500px) {
  .ballhand {
    position: relative;
    left: -10px;
  }
}

.featuredslip {
  background: linear-gradient(
  90deg,
  rgba(47, 20, 2, 0.94),
  rgb(182, 124, 1)
);
color: #fff;
}

/* betting icons */

.bettingicons {
  max-height: 50px;
  margin-top: 10px;
  max-width: 50px;
  width: auto;
}

.bettingicons2 {
  max-height: 50px;
  max-width: 50px;
  width: auto;
}
.bettingicons3 {
  max-height: 30px;
  max-width: 30px;
  width: auto;
}

.roundround {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background-color: black;
}
.roundround2 {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  background-color: black;
}
.roundround3 {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: black;
}

.pointer {
  cursor: pointer;
}
.clickable-row:hover {
  background: #fffbfb62;
  /* background: rgb(53, 53, 53); */
}
p {
  font-weight: 400;
}

.skeleton {
  animation: skeleton-loading 0.5s linear infinite alternate;
}

.skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 10px;
}
.skeleton-btn {
  width: 100px;
  height: 2rem;
  margin-bottom: 0.25rem;
  border-radius: 10px;
}

.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.overflow-auto {
    overflow-x: auto;
    white-space: nowrap;
}

.overflow-auto::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
</style>

