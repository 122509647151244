<template>
    <div>
        <div class="container-fluid my-4">

            <div class="row" v-for="sub in activeSubscription" :key="sub.flw_ref">
            <div class="col-md-10 offset-md-1 mb-4">
                <div class="card-mine text-center"  :class="sub.subscription_plan == 'weekly' ? 'card_red' : sub.subscription_plan == 'monthly' ? 'card_violet' : 'card_three' ">
                  <div class="title">
                    <i class="fas fa-crown" aria-hidden="true"></i>                    
                    <h2  >Subscription: <span style="background:linear-gradient(45deg,gold,#fc742a);  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;">{{sub.subscription_plan == 'annually' ? '3 Months' : sub.subscription_plan  }}</span></h2>
                  </div>
                  <div class="price">
                    <h4 v-if="sub.subscription_plan == 'weekly'"><span class="badge badge-primary" style="border-radius:20px;">$10</span></h4>
                    <h4 v-else-if="sub.subscription_plan == 'monthly'"><span class="badge badge-primary" style="border-radius:20px;">$35</span></h4>
                    <h4 v-else><span class="badge badge-primary" style="border-radius:20px;">$60</span></h4>
                  </div>
                  <div class="option">
                        <ul>
                            
                            <li> <span class="badge badge-success">Start Date: </span> {{sub.startdate}}</li>
                            <li class="my-2"><span class="badge badge-danger">End Date: </span> {{sub.enddate}}</li>
                            
                        </ul>
                  </div>
                  <a href="#" class="projects  scroll-to-top rounded">Home</a>
                </div>
            </div>
            </div>
            <!-- <div class="card text-center" style="border-left:solid 4px teal;border-right:solid 4px teal;">
                <div class="card-body">
                    <h6><i class="fas fa-crown mr-1"></i> My Active Subscription</h6>

                    <hr>

                   <div class="table-responsive">
                       <table class="table table-striped">
                           <tbody v-for="sub in activeSubscription" :key="sub.flw_ref">
                               <tr>
                                   <td style="font-weight:bold;">Subscription Type:</td>
                                   <td>{{sub.subscription_plan}}</td>
                               </tr>
                               <tr>
                                   <td style="font-weight:bold;">Amount:</td>
                                   <td>$10</td>
                               </tr>
                               <tr>
                                   <td style="font-weight:bold;">Start Date:</td>
                                   <td>{{sub.startdate}}.</td>
                               </tr>
                               <tr>
                                   <td style="font-weight:bold;">End Date:</td>
                                   <td>{{sub.enddate}}.</td>
                               </tr>
                           </tbody>
                       </table>
                   </div>

                                  
                </div>
            </div> -->
        </div>
    </div>
</template>



<script>
import { db } from '../db'
import { ref, set, query, onValue,orderByChild,equalTo ,limitToLast, endBefore, endAt, startAfter } from "firebase/database";

export default {
    name: "Account",
    components: {

    },

    data() {
        return {
            activeSubscription: [],
            haveActiveSub: false,
        }
    },

    mounted() {                
       
            const userid = localStorage.getItem('uid')
            let todaysTimeStamp = new Date().getTime()
            const funcka = query(ref(db, 'subscriptions'), orderByChild('userid'), equalTo(userid));
                            onValue(funcka, (snapshot) => {
                                if (snapshot.exists()) {
                                    const data = snapshot.val();
                                    let messages2 = [];
                                    Object.keys(data).forEach(key => {
                                        
                                        if(data[key].end_date > todaysTimeStamp){
                                            messages2.push({
                                                id:key,
                                                userid:data[key].userid,  
                                                startdate :new Date(data[key].start_date) ,                                   
                                                subscription_plan :data[key].subscription_plan,                                    
                                                flw_ref :data[key].flw_ref,                                    
                                                enddate :new Date(data[key].end_date) ,                                    
                                            })
                                        }
                                    });
                                    
                                    console.log(messages2.length + 'is the length');

                                    if (messages2.length >= 1) {
                                        this.haveActiveSub = true
                                    } else {
                                       this.haveActiveSub = false
                                    }

                                    this.activeSubscription = messages2;
                                }
                                else {
                                    this.haveActiveSub = false
                                }                   
                            });
            
        
    },
    
}
</script>


<style scoped src="@/assets/style.css">

.card:before {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px) saturate(100%) contrast(45%) brightness(130%);
  height: 100%;
  position: absolute;
  width: 100%;
}
    
</style>